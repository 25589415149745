window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.authentication = window.locales.am.authentication || {};window.locales.am.authentication.sign_up_form = window.locales.am.authentication.sign_up_form || {};window.locales.am.authentication.sign_up_form = {...window.locales.am.authentication.sign_up_form, ...{
    "company": "ኩባንያ",           
    "country": "ሀገር",           
    "email": "የኢሜይል አድራሻ",           
    "select": "ይምረጡ",           
    "experience_a_demo": "በ {{brandName}} ትምህርት ዛሬ ለራስዎ ልምድ ይውሰዱ",           
    "job_title": "የስራ መደቡ መጠሪያ",           
    "join": "መለያ ይፍጠሩ",           
    "join_demo": "ማሳያ ጀምር",           
    "name": "ሙሉ ስም",           
    "name_short": "ስም",           
    "or_enter_info_below": "ወይም ከታች መረጃውን ያስገቡ",           
    "password": "የይለፍ ቃል",           
    "location": "ቦታ",           
    "password_min_length": "የይለፍ ቃል ቢያንስ 8 አሀዞች ርዝመት ሊኖረው ይገባል",           
    "phone": "ስልክ ቁጥር",           
    "school_optional": "ትምህርት ቤት (አማራጭ)",           
    "server_error": "የሰርቨር ስህተት ተከስቷል። {{brandName}} መሀንዲሶች እንዲያውቁ ተደርጓል",           
    "sign_up_its_free": "ይመዝገቡ - ነፃ ነው።",           
    "sign_up_for_free_using": "የሚከተለውን በመጠቀም በነጻ ይመዝገቡ",           
    "sign_up_using_phone_number": "በስልክ ቁጥርዎ ይመዝገቡ",           
    "sign_up_using_emu_email": "በ EMU ኢሜይል አድራሻዎ ይመዝገቡ",           
    "sign_up_using_georgetown_email": "በጆርጅታውን ኢሜይል አድራሻዎ ይመዝገቡ",           
    "sign_up_using_nyu_email": "በ Stern ኢሜይል አድራሻዎ ይመዝገቡ",           
    "sign_up_using_inframark_email": "በ Inframark ኢሜይል አድራሻዎ ይመዝገቡ",           
    "miyamiya_title_message": "ለ <strong>Miya Miya</strong> ይመዝገቡ",           
    "company_employee_count": "በድርጅትዎ ውስጥ ስንት ሰራተኞች አሉ?",           
    "company_year": "ኩባንያዎ በየትኛው ዓመት ላይ ተመሰረተ?",           
    "company_annual_revenue": "የኩባንያዎ ዓመታዊ ገቢ ምን ያህል ነው?",           
    "apply": "ያመልክቱ",           
    "no": "አይ",           
    "yes": "አዎ",           
    "professional_organization": "ኩባንያ",           
    "default_placeholder": "{{}}",           
    "sign_up_with_google": "በጉግል ይመዝገቡ",           
    "sign_up_with_facebook": "በፌስቡክ ይመዝገቡ",           
    "sign_up_with_apple": "በአፕል ይመዝገቡ",           
    "or": "ወይም",           
    "name_with_star": "ስም *",           
    "name_placeholder": "ሙሉ ስም ያስገቡ",           
    "email_placeholder": "email@provider.com",
    "name_error_required": "እባክዎ ስምዎን ያስገቡ",           
    "email_address_with_star": "የኢሜይል አድራሻ *",           
    "email_address_error_required": "እባክዎ የኢሜይል አድራሻዎን ይጠቀሙ",           
    "password_with_star": "የይለፍ ቃል *",           
    "password_error_required": "እባክዎ የይለፍ ቃልዎን ያስገቡ",           
    "sign_up": "ይመዝገቡ",           
    "submit": "ያስገቡ",           
    "already_have_account": "ቀድሞውኑ መለያ አለዎት?",           
    "sign_up_for_free": "በነጻ ለ... ይመዝገቡ",           
    "log_in": "ይግቡ",           
    "get_signed_up": "እናስመዘግብዎት። ነፃ ነው!",           
    "location_error_required": "እባክዎ ቦታ ይምረጡ",           
    "sending": "በመላክ ላይ",           
    "sign_up_with_wechat": "በዊቻት ይመዝገቡ",           
    "valar_title_message": "ተመዝግበህ እናስመዘግብህ።<br /> ነፃ ነው!",
    "apply_to_emba": "ለስራ አስፈፃሚ MBA ያመልክቱ",
    "immediate_access": "40+ ነፃ ትምህርቶችን ወዲያውኑ ይድረሱ",
    "preview_global_alumni_network": "የአለምአቀፍ የቀድሞ ተማሪዎች አውታረ መረብን አስቀድመው ይመልከቱ",
    "please_enter_your_blank": "እባክዎ የእርስዎን {{name}} ያስገቡ",
    "privacy_notice": "የእርስዎን ግላዊነት እናከብራለን። የእርስዎን ውሂብ በፍፁም አንሸጥም፣ እና የእርስዎን መረጃ ደህንነቱ የተጠበቀ እና ደህንነቱ የተጠበቀ ለማድረግ እንተጋለን። ከምዝገባ በኋላ፣ ስለ አስፈላጊ የማመልከቻ ጊዜ ገደብ እና ማስታወቂያዎች አልፎ አልፎ በኢሜል ልንልክልዎት እንችላለን። በማንኛውም ጊዜ የማሳወቂያ ምርጫዎችዎን ማስተካከል ወይም መለያዎ እንዲወገድ መጠየቅ ይችላሉ። ለተጨማሪ ዝርዝሮች የእኛን <a href=\"https://{{domain}}/privacy\">የግላዊነት መመሪያ</a> ይመልከቱ።",
    "apply_mba_degree_programs": "ለ MBA ወይም አስፈፃሚ MBA ዲግሪ ፕሮግራሞች ያመልክቱ",
    "access_course_library": "የእኛን የነጻ ኮርስ ቤተ-መጽሐፍት ይድረሱ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.authentication = window.locales.ar.authentication || {};window.locales.ar.authentication.sign_up_form = window.locales.ar.authentication.sign_up_form || {};window.locales.ar.authentication.sign_up_form = {...window.locales.ar.authentication.sign_up_form, ...{
    "company": "الشركة",           
    "country": "البلد",           
    "email": "عنوان البريد الإلكتروني",           
    "select": "تحديد",           
    "job_title": "المسمى الوظيفي",           
    "join": "أنشئ حساباً",           
    "join_demo": "ابدأ في استخدام الإصدار التجريبي",           
    "or_enter_info_below": "أو أدخل المعلومات أدناه",           
    "password": "كلمة المرور",           
    "location": "الموقع",           
    "password_min_length": "يجب ألا تكون كلمة المرور أقل من 8 أحرف",           
    "phone": "رقم الهاتف",           
    "school_optional": "المدرسة (اختياري)",           
    "sign_up_its_free": "قُم بالاشتراك الأن، إنه مجاني",           
    "sign_up_for_free_using": "قُم بالاشتراك مجاناً باستخدام",           
    "sign_up_using_phone_number": "قُم بالاشتراك بواسطة رقم هاتفك",           
    "sign_up_using_emu_email": "قُم بالاشتراك بواسطة عنوان بريدك الإلكتروني على EMU",           
    "sign_up_using_georgetown_email": "قُم بالاشتراك بواسطة عنوان بريدك الإلكتروني على Georgetown",           
    "company_employee_count": "كم عدد الموظفين في شركتك؟",           
    "company_year": "ما هو العام الذي تأسست فيه شركتك؟",           
    "company_annual_revenue": "ما هو العائد السنوي لشركتك؟",           
    "apply": "تقديم",           
    "no": "لا",           
    "yes": "نعم",           
    "professional_organization": "شركة",
    "name_short": "اسم",
    "default_placeholder": "{{}}",
    "name": "الاسم الكامل",
    "sign_up_using_nyu_email": "اشترك مع عنوان البريد الإلكتروني لجامعة نيويورك",
    "sign_up_using_inframark_email": "اشترك مع عنوان البريد الإلكتروني Inframark الخاص بك",
    "miyamiya_title_message": "التسجيل في <strong>مية مية</strong>",
    "experience_a_demo": "تجربة درس {{brandName}} لنفسك اليوم",
    "server_error": "حدث خطأ في الخادم. {{brandName}} تم إخطار المهندسين",
    "sign_up_with_google": "اشترك مع جوجل",
    "sign_up_with_facebook": "اشترك عبر حساب فايسبوك",
    "sign_up_with_apple": "قم بالتسجيل مع Apple",
    "or": "أو",
    "name_with_star": "اسم *",
    "name_placeholder": "أدخل الاسم الكامل",
    "email_placeholder": "email@provider.com",
    "name_error_required": "يرجى إدخال اسمك",
    "email_address_with_star": "عنوان البريد الإلكتروني *",
    "email_address_error_required": "الرجاء استخدام عنوان البريد الإلكتروني الخاص بك",
    "password_with_star": "كلمه السر *",
    "password_error_required": "من فضلك أدخل رقمك السري",
    "sign_up": "اشتراك",
    "submit": "يقدم",
    "already_have_account": "هل لديك حساب؟",
    "sign_up_for_free": "اشترك مجانا ...",
    "log_in": "تسجيل دخول",
    "get_signed_up": "دعنا نجعلك تسجل. انه مجانا!",
    "location_error_required": "الرجاء تحديد موقع",
    "sending": "إرسال",
    "sign_up_with_wechat": "قم بالتسجيل مع WeChat",
    "valar_title_message": "لنجعلك تسجل.<br /> انه مجانا!",
    "apply_to_emba": "التقدم إلى ماجستير إدارة الأعمال التنفيذية",
    "immediate_access": "الوصول فورًا إلى أكثر من 40 درسًا مجانيًا",
    "preview_global_alumni_network": "معاينة شبكة الخريجين العالمية",
    "please_enter_your_blank": "الرجاء إدخال {{name}} الخاص بك",
    "privacy_notice": "نحن نقيم مستوى الخصوصية لديك. لن نبيع بياناتك أبدًا ، ونسعى جاهدين للحفاظ على معلوماتك آمنة ومأمونة. بعد التسجيل ، قد نرسل إليك بريدًا إلكترونيًا من حين لآخر بشأن المواعيد النهائية والإعلانات المهمة لتقديم الطلبات. يمكنك في أي وقت تعديل تفضيلات الإشعارات أو طلب إزالة حسابك. لمزيد من التفاصيل ، راجع <a href=\"https://{{domain}}/privacy\">سياسة الخصوصية الخاصة</a> بنا.",
    "apply_mba_degree_programs": "التقديم على درجة الماجستير في إدارة الأعمال أو برامج درجة الماجستير في إدارة الأعمال التنفيذية",
    "access_course_library": "الوصول إلى مكتبة الدورات المجانية الخاصة بنا"
}
};window.locales.en = window.locales.en || {};window.locales.en.authentication = window.locales.en.authentication || {};window.locales.en.authentication.sign_up_form = window.locales.en.authentication.sign_up_form || {};window.locales.en.authentication.sign_up_form = {...window.locales.en.authentication.sign_up_form, ...{
    "company": "Company",
    "country": "Country",
    "email": "Email Address",
    "select": "Select",
    "experience_a_demo": "Experience a {{brandName}} lesson for yourself today",
    "job_title": "Job Title",
    "join": "Create Account",
    "join_demo": "Start Demo",
    "name": "Full Name",
    "name_short": "Name",
    "or_enter_info_below": "Or enter the information below",
    "password": "Password",
    "location": "Location",
    "password_min_length": "Password must be at least 8 characters long",
    "phone": "Phone Number",
    "school_optional": "School (Optional)",
    "server_error": "A server error occurred. {{brandName}} engineers have been notified",
    "sign_up_its_free": "Sign Up - It's Free",
    "sign_up_for_free_using": "Sign up for free using",
    "sign_up_using_phone_number": "Sign up with your phone number",
    "sign_up_using_emu_email": "Sign up with your EMU email address",
    "sign_up_using_georgetown_email": "Sign up with your Georgetown email address",
    "sign_up_using_nyu_email": "Sign up with your Stern email address",
    "sign_up_using_inframark_email": "Sign up with your Inframark email address",
    "miyamiya_title_message": "Sign up for <strong>Miya Miya</strong>",
    "valar_title_message": "Let&rsquo;s Get You Signed Up. <br /> It&rsquo;s Free!",
    "company_employee_count": "How many employees are in your company?",
    "company_year": "What year was your company founded?",
    "company_annual_revenue": "What is your company's annual revenue?",
    "apply": "Apply",
    "no": "No",
    "yes": "Yes",
    "professional_organization": "Company",
    "default_placeholder": "{{}}",
    "privacy_notice": "We value your privacy. We will never sell your data, and we strive to keep your information safe and secure. After registration, we may occasionally email you about important application deadlines and announcements. At any time, you may adjust your notification preferences or request removal of your account. For further details, see our <a href=\"https://{{domain}}/privacy\">privacy policy</a>.",
    "sign_up_with_google": "Sign Up with Google",
    "sign_up_with_facebook": "Sign Up with Facebook",
    "sign_up_with_apple": "Sign up with Apple",
    "sign_up_with_wechat": "Sign up with WeChat",
    "or": "Or",
    "name_with_star": "Name *",
    "name_placeholder": "Enter full name",
    "email_placeholder": "email@provider.com",
    "name_error_required": "Please enter your name",
    "email_address_with_star": "Email address *",
    "email_address_error_required": "Please use your email address",
    "password_with_star": "Password *",
    "password_error_required": "Please enter your password",
    "sign_up": "Sign Up",
    "submit": "Submit",
    "already_have_account": "Already have an account?",
    "sign_up_for_free": "Sign up for free to...",
    "log_in": "Log In",
    "get_signed_up": "Let's get you signed up. It's free!",
    "apply_mba_degree_programs": "Apply to the MBA or Executive MBA degree programs",
    "apply_to_emba": "Apply to the Executive MBA",
    "immediate_access": "Immediately Access 40+ Free Lessons",
    "preview_global_alumni_network": "Preview the Global Alumni Network",
    "access_course_library": "Access our free course library",
    "location_error_required": "Please select a location",
    "sending": "Sending",
    "please_enter_your_blank": "Please enter your {{name}}"
}
};window.locales.es = window.locales.es || {};window.locales.es.authentication = window.locales.es.authentication || {};window.locales.es.authentication.sign_up_form = window.locales.es.authentication.sign_up_form || {};window.locales.es.authentication.sign_up_form = {...window.locales.es.authentication.sign_up_form, ...{
    "company": "Empresa",           
    "country": "País",           
    "email": "Dirección de correo electrónico",           
    "job_title": "Puesto de trabajo",           
    "join": "Crear cuenta",           
    "join_demo": "Iniciar demostración",           
    "name": "Nombre",           
    "or_enter_info_below": "O introduce la siguiente información",           
    "password": "Contraseña",           
    "password_min_length": "La contraseña debe tener al menos 8 caracteres",           
    "phone": "Número de teléfono",           
    "school_optional": "Escuela (Opcional)",           
    "sign_up_for_free_using": "Inscríbete gratis utilizando",           
    "sign_up_using_phone_number": "Regístrese con su número de teléfono",           
    "company_employee_count": "¿Cuántos empleados son en su empresa?",           
    "company_year": "¿En qué año se fundó su empresa?",           
    "company_annual_revenue": "¿Cuál es el ingreso anual de su empresa?",           
    "sign_up_using_emu_email": "Regístrese con su dirección de correo electrónico de la EMU",           
    "sign_up_using_georgetown_email": "Regístrese con su dirección de correo electrónico de Georgetown",           
    "select": "Seleccionar",           
    "location": "Ubicación",           
    "apply": "Aplicar",           
    "no": "No",           
    "yes": "Sí",           
    "sign_up_its_free": "Registrese, es gratis",           
    "professional_organization": "Empresa",
    "name_short": "Nombre",
    "default_placeholder": "{{}}",
    "sign_up_using_nyu_email": "Regístrese con su dirección de correo electrónico Stern",
    "sign_up_using_inframark_email": "Regístrese con su dirección de correo electrónico de Inframark",
    "miyamiya_title_message": "Registrarse en <strong>Miya Miya</strong>",
    "experience_a_demo": "Experimente una lección de {{brandName}} para usted hoy",
    "server_error": "Se produjo un error en el servidor. {{brandName}} ingenieros de {{brandName}} han sido notificados",
    "sign_up_with_google": "Registrarse con Google",
    "sign_up_with_facebook": "Registrate con Facebook",
    "sign_up_with_apple": "Regístrese con Apple",
    "or": "O",
    "name_with_star": "Nombre *",
    "name_placeholder": "Ingrese su nombre completo",
    "email_placeholder": "email@provider.com",
    "name_error_required": "por favor, escriba su nombre",
    "email_address_with_star": "Dirección de correo electrónico *",
    "email_address_error_required": "Utilice su dirección de correo electrónico",
    "password_with_star": "Contraseña *",
    "password_error_required": "Por favor, introduzca su contraseña",
    "sign_up": "Inscribirse",
    "submit": "Enviar",
    "already_have_account": "¿Ya tienes una cuenta?",
    "sign_up_for_free": "Regístrate gratis para ...",
    "log_in": "Iniciar sesión",
    "get_signed_up": "Vamos a inscribirte. ¡Es gratis!",
    "location_error_required": "Seleccione una ubicación",
    "sending": "Enviando",
    "sign_up_with_wechat": "Regístrese con WeChat",
    "valar_title_message": "Hagamos que te registres.<br /> ¡Es gratis!",
    "apply_to_emba": "Aplica al Executive MBA",
    "immediate_access": "Acceda inmediatamente a más de 40 lecciones gratuitas",
    "preview_global_alumni_network": "Vista previa de la Red Global de Antiguos Alumnos",
    "please_enter_your_blank": "Por favor ingrese su {{name}}",
    "privacy_notice": "Valoramos su privacidad. Nunca venderemos sus datos y nos esforzamos por mantener su información segura y protegida. Después del registro, es posible que ocasionalmente le enviemos correos electrónicos sobre fechas límite de solicitud y anuncios importantes. En cualquier momento, puede ajustar sus preferencias de notificación o solicitar la eliminación de su cuenta. Para más detalles, consulte nuestra <a href=\"https://{{domain}}/privacy\">política de privacidad</a> .",
    "apply_mba_degree_programs": "Aplicar a los programas de grado MBA o Executive MBA",
    "access_course_library": "Accede a nuestra biblioteca de cursos gratuitos"
}
};window.locales.it = window.locales.it || {};window.locales.it.authentication = window.locales.it.authentication || {};window.locales.it.authentication.sign_up_form = window.locales.it.authentication.sign_up_form || {};window.locales.it.authentication.sign_up_form = {...window.locales.it.authentication.sign_up_form, ...{
    "company": "Azienda",           
    "country": "Paese",           
    "email": "Indirizzo email",           
    "select": "Seleziona",           
    "job_title": "Titolo Professionale",           
    "join": "Crea Account",           
    "join_demo": "Inizia Demo",           
    "name": "Nome e Cognome",           
    "name_short": "Nome",           
    "or_enter_info_below": "O inserisci le informazioni in basso",           
    "password": "Password",           
    "location": "Località",           
    "password_min_length": "La password deve essere lunga almeno 8 caratteri",           
    "phone": "Numero di telefono",           
    "school_optional": "Scuola (opzionale)",           
    "sign_up_its_free": "Registrati - È gratis",           
    "sign_up_for_free_using": "Registrati gratuitamente usando",           
    "sign_up_using_phone_number": "Registrati con il tuo numero di telefono",           
    "sign_up_using_emu_email": "Registrati con il tuo indirizzo email EMU",           
    "sign_up_using_georgetown_email": "Registrati con il tuo indirizzo email Georgetown",           
    "sign_up_using_nyu_email": "Registrati con il tuo indirizzo email Stern",           
    "company_employee_count": "Quanti lavoratori impiega la tua azienda?",           
    "company_year": "In che anno è nata la tua azienda?",           
    "company_annual_revenue": "Qual è il fatturato annuo della tua azienda?",           
    "apply": "Candidati",           
    "no": "No",           
    "yes": "Sì",           
    "professional_organization": "Azienda",           
    "default_placeholder": "{{}}",           
    "sign_up_using_inframark_email": "Iscriviti con il tuo indirizzo email Inframark",
    "miyamiya_title_message": "Iscriviti a <strong>Miya Miya</strong>",
    "experience_a_demo": "Prova oggi una lezione {{brandName}} per te stesso",
    "server_error": "Si è verificato un errore del server. {{brandName}} ingegneri {{brandName}} sono stati avvisati",
    "sign_up_with_google": "Registrati con Google",
    "sign_up_with_facebook": "Iscriviti con Facebook",
    "sign_up_with_apple": "Registrati con Apple",
    "or": "o",
    "name_with_star": "Nome *",
    "name_placeholder": "Inserisci il nome completo",
    "email_placeholder": "email@provider.com",
    "name_error_required": "per favore inserisci il tuo nome",
    "email_address_with_star": "Indirizzo email *",
    "email_address_error_required": "Per favore usa il tuo indirizzo email",
    "password_with_star": "Parola d'ordine *",
    "password_error_required": "per favore inserisci LA TUA password",
    "sign_up": "Iscriviti",
    "submit": "Invia",
    "already_have_account": "Hai già un account?",
    "sign_up_for_free": "Iscriviti gratuitamente a...",
    "log_in": "Accesso",
    "get_signed_up": "Ti facciamo iscrivere. È gratis!",
    "location_error_required": "Si prega di selezionare una posizione",
    "sending": "Invio",
    "sign_up_with_wechat": "Iscriviti a WeChat",
    "valar_title_message": "Ti facciamo iscrivere.<br /> È gratis!",
    "apply_to_emba": "Fai domanda per l'Executive MBA",
    "immediate_access": "Accedi immediatamente a oltre 40 lezioni gratuite",
    "preview_global_alumni_network": "Visualizza in anteprima il Global Alumni Network",
    "please_enter_your_blank": "Per favore inserisci il tuo {{name}}",
    "privacy_notice": "Apprezziamo la tua privacy. Non venderemo mai i tuoi dati e ci sforziamo di mantenere le tue informazioni al sicuro. Dopo la registrazione, potremmo occasionalmente inviarti un'e-mail in merito a scadenze e annunci importanti per le domande. In qualsiasi momento, puoi modificare le tue preferenze di notifica o richiedere la rimozione del tuo account. Per ulteriori dettagli, consultare la nostra <a href=\"https://{{domain}}/privacy\">politica sulla privacy</a> .",
    "apply_mba_degree_programs": "Applicare ai corsi di laurea MBA o Executive MBA",
    "access_course_library": "Accedi alla nostra libreria di corsi gratuita"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.authentication = window.locales.zh.authentication || {};window.locales.zh.authentication.sign_up_form = window.locales.zh.authentication.sign_up_form || {};window.locales.zh.authentication.sign_up_form = {...window.locales.zh.authentication.sign_up_form, ...{
    "company": "公司",           
    "country": "国家/地区",           
    "email": "电子邮件",           
    "email_address": "电子邮件地址",           
    "job_title": "职务",           
    "join": "加入",           
    "join_demo": "开始演示",           
    "or_enter_info_below": "或者在下面输入信息",           
    "password": "密码",           
    "password_min_length": "密码长度至少应有 8 个字符",           
    "phone": "电话号码",           
    "school_optional": "学校（可选）",           
    "sign_up_for_free_using": "注册免费使用",           
    "sign_up_using_phone_number": "使用您的电话号码注册",           
    "company_employee_count": "您的公司有多少员工？",           
    "company_year": "您的公司于哪一年成立？",           
    "company_annual_revenue": "您的公司的年收入是多少？",           
    "sign_up_using_emu_email": "使用您的EMU电子邮箱注册",           
    "sign_up_using_georgetown_email": "使用您的乔治敦电子邮箱注册",           
    "select": "选择",           
    "location": "位置",           
    "apply": "使用",           
    "no": "否",           
    "yes": "是",           
    "sign_up_its_free": "注册 - 免费",           
    "professional_organization": "公司",           
    "name_short": "姓名",           
    "default_placeholder": "{{}}",           
    "name": "全名",           
    "sign_up_using_nyu_email": "用你的Stern电子邮箱注册",           
    "sign_up_using_inframark_email": "用你的Inframark电子邮箱注册",           
    "miyamiya_title_message": "注册<strong>Miya Miya</strong>账号",           
    "experience_a_demo": "今天上一节{{brandName}}的课",           
    "server_error": "服务器错误。已通知{{brandName}}工程师",           
    "sign_up_with_google": "使用Google账号注册",           
    "sign_up_with_facebook": "使用Facebook账号注册",           
    "sign_up_with_apple": "使用Apple账号注册",           
    "or": "或者",
    "name_with_star": "姓名 *",
    "name_placeholder": "请填写全名",           
    "email_placeholder": "申请资料将发送到该邮箱",           
    "name_error_required": "请输入您的名字",           
    "email_address_with_star": "电子邮箱*",           
    "email_address_error_required": "请使用您的电子邮件地址",
    "password_with_star": "设置登录密码*",           
    "password_error_required": "请输入您的密码",
    "sign_up": "立即注册",           
    "submit": "提交",
    "already_have_account": "已经有账号？",
    "sign_up_for_free": "立即注册...",
    "log_in": "登录",
    "get_signed_up": "免费申请报读英文MBA/EMBA项目，提升您的职场竞争力!",           
    "location_error_required": "请选择位置",
    "sending": "发送中",
    "sign_up_with_wechat": "使用微信注册",
    "valar_title_message": "让我们让您注册。<br />免费！",
    "apply_to_emba": "申请行政工商管理硕士",
    "immediate_access": "立即访问 40 多节免费课程",
    "preview_global_alumni_network": "预览全球校友网络",
    "please_enter_your_blank": "请输入您的{{name}}",
    "privacy_notice": "我们重视您的隐私。我们绝不会出售您的数据，并努力确保您的信息安全可靠。注册后，我们有时可能会通过电子邮件向您发送重要的申请截止日期和公告。您可以随时调整您的通知偏好或要求删除您的帐户。有关详细信息，请参阅我们的<a href=\"https://{{domain}}/privacy\">隐私政策</a>。",
    "apply_mba_degree_programs": "申请MBA或EMBA学位课程",
    "access_course_library": "访问我们的免费课程库"
}
};