window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.admissions_modals = window.locales.am.admissions_modals || {};window.locales.am.admissions_modals.application_received_modal = window.locales.am.admissions_modals.application_received_modal || {};window.locales.am.admissions_modals.application_received_modal = {...window.locales.am.admissions_modals.application_received_modal, ...{
    "we_received_your_application": "ማመልከቻህን ተቀብለናል!",
    "need_answers": "መልሶች ይፈልጋሉ?<br/> <a ng-click=\"onClickResources()\">መርጃዎችን ያስሱ</a>",
    "need_guidance": "1፡1 መመሪያ ይፈልጋሉ?<br/> <a ng-click=\"scheduleAdvisorChat()\">የአማካሪ ውይይት ያቅዱ</a>",
    "friends_or_colleagues": "ጓደኞችን ወይም የስራ ባልደረቦችን ያግኙ<br/> <a ng-click=\"onClickAlumniNetwork()\">የተመራቂዎች አውታረ መረብን ይፈልጉ</a>",
    "begin_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">ክፍት ኮርሶችን ይጀምሩ</a>",
    "continue_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">ክፍት ኮርሶችን ይቀጥሉ</a>",
    "review_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">ክፍት ኮርሶችን ይገምግሙ</a>",
    "foundations_completed": "{{foundationsPlaylistTitle}} ጨርሰዋል። ተጨማሪ ክፍት ኮርሶችን <a ng-click=\"onClickCoursesTab()\">በኮርሶች</a> ትር ላይ ማግኘት ይችላሉ።",
    "complete_first_course": "መስተጋብራዊ ስርአተ ትምህርታችንን ናሙና ለማድረግ እና ለመግቢያ ቡድናችን ማበረታቻን ለማሳየት <a ng-click=\"onClickDashboard()\">የመጀመሪያውን ኮርስ</a> ያጠናቅቁ።",
    "continue_courses_business_admin": "ቁልፍ የንግድ ፅንሰ ሀሳቦችን እና የዲግሪ ዝግጁነትን ለመቀበያ ቡድናችን ለማመልከት <a ng-click=\"onClickDashboard()\">ኮርሶችን ይቀጥሉ</a> ።",
    "continue_courses_business_analytics": "ቁልፍ የንግድ ትንተና ፅንሰ-ሀሳቦችን እና የዲግሪ ዝግጁነትን ለመግቢያ ቡድናችን ለማመልከት <a ng-click=\"onClickDashboard()\">ኮርሶችን ይቀጥሉ</a> ።",
    "continue_courses_software_engineering": "ቁልፍ የሶፍትዌር ምህንድስና ፅንሰ-ሀሳቦችን እና የምልክት ዲግሪ ዝግጁነትን ለመግቢያ ቡድናችን ለማጠናከር <a ng-click=\"onClickDashboard()\">ኮርሶችን ይቀጥሉ</a> ።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.admissions_modals = window.locales.ar.admissions_modals || {};window.locales.ar.admissions_modals.application_received_modal = window.locales.ar.admissions_modals.application_received_modal || {};window.locales.ar.admissions_modals.application_received_modal = {...window.locales.ar.admissions_modals.application_received_modal, ...{
    "we_received_your_application": "لقد تلقينا طلبك!",
    "need_answers": "هل تحتاج إلى إجابات؟<br/> <a ng-click=\"onClickResources()\">تصفح الموارد</a>",
    "need_guidance": "هل تحتاج إلى إرشادات 1:1؟<br/> <a ng-click=\"scheduleAdvisorChat()\">جدولة محادثة المستشار</a>",
    "friends_or_colleagues": "ابحث عن أصدقاء أو زملاء<br/> <a ng-click=\"onClickAlumniNetwork()\">بحث في شبكة الخريجين</a>",
    "begin_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">ابدأ الدورات المفتوحة</a>",
    "continue_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">مواصلة الدورات المفتوحة</a>",
    "review_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">مراجعة الدورات المفتوحة</a>",
    "foundations_completed": "لقد أكملت {{foundationsPlaylistTitle}} . يمكنك العثور على دورات مفتوحة إضافية في علامة التبويب <a ng-click=\"onClickCoursesTab()\">الدورات</a> .",
    "complete_first_course": "أكمل <a ng-click=\"onClickDashboard()\">الدورة التدريبية الأولى</a> لتجربة منهجنا التفاعلي وإرسال التحفيز لفريق القبول لدينا.",
    "continue_courses_business_admin": "<a ng-click=\"onClickDashboard()\">مواصلة الدورات التدريبية</a> لتعزيز مفاهيم الأعمال الرئيسية والإشارة إلى استعداد الحصول على الدرجة العلمية لفريق القبول لدينا.",
    "continue_courses_business_analytics": "<a ng-click=\"onClickDashboard()\">مواصلة الدورات التدريبية</a> لتعزيز مفاهيم تحليلات الأعمال الأساسية والإشارة إلى استعداد الحصول على الدرجة العلمية لفريق القبول لدينا.",
    "continue_courses_software_engineering": "<a ng-click=\"onClickDashboard()\">مواصلة الدورات التدريبية</a> لترسيخ المفاهيم الأساسية في هندسة البرمجيات والإشارة إلى جاهزية الحصول على الدرجة العلمية لفريق القبول لدينا."
}
};window.locales.en = window.locales.en || {};window.locales.en.admissions_modals = window.locales.en.admissions_modals || {};window.locales.en.admissions_modals.application_received_modal = window.locales.en.admissions_modals.application_received_modal || {};window.locales.en.admissions_modals.application_received_modal = {...window.locales.en.admissions_modals.application_received_modal, ...{
    "we_received_your_application": "We received your application!",
    "need_answers": "Need answers?<br/><a ng-click=\"onClickResources()\">Browse resources</a>",
    "need_guidance": "Need 1:1 guidance?<br/><a ng-click=\"scheduleAdvisorChat()\">Schedule an advisor chat</a>",
    "friends_or_colleagues": "Find friends or colleagues<br/><a ng-click=\"onClickAlumniNetwork()\">Search alumni network</a>",
    "begin_open_courses": "{{foundationsPlaylistTitle}}<br/><a ng-click=\"onClickOpenCourses()\">Begin open courses</a>",
    "continue_open_courses": "{{foundationsPlaylistTitle}}<br/><a ng-click=\"onClickOpenCourses()\">Continue open courses</a>",
    "review_open_courses": "{{foundationsPlaylistTitle}}<br/><a ng-click=\"onClickOpenCourses()\">Review open courses</a>",
    "complete_first_course": "Complete the <a ng-click=\"onClickDashboard()\">first course</a> to sample our interactive curriculum and signal motivation to our Admissions team.",
    "continue_courses_business_admin": "<a ng-click=\"onClickDashboard()\">Continue courses</a> to cement key business concepts and signal degree readiness to our Admissions team.",
    "continue_courses_business_analytics": "<a ng-click=\"onClickDashboard()\">Continue courses</a> to cement key business analytics concepts and signal degree readiness to our Admissions team.",
    "continue_courses_software_engineering": "<a ng-click=\"onClickDashboard()\">Continue courses</a> to cement key software engineering concepts and signal degree readiness to our Admissions team.",
    "foundations_completed": "You have completed {{foundationsPlaylistTitle}}. You can find additional open courses on the <a ng-click=\"onClickCoursesTab()\">Courses</a> tab."
}
};window.locales.es = window.locales.es || {};window.locales.es.admissions_modals = window.locales.es.admissions_modals || {};window.locales.es.admissions_modals.application_received_modal = window.locales.es.admissions_modals.application_received_modal || {};window.locales.es.admissions_modals.application_received_modal = {...window.locales.es.admissions_modals.application_received_modal, ...{
    "we_received_your_application": "¡Recibimos tu solicitud!",
    "need_answers": "¿Necesitas respuestas?<br/> <a ng-click=\"onClickResources()\">Explorar recursos</a>",
    "need_guidance": "¿Necesita orientación 1:1?<br/> <a ng-click=\"scheduleAdvisorChat()\">Programe una charla con asesor</a>",
    "friends_or_colleagues": "Encuentra amigos o colegas<br/> <a ng-click=\"onClickAlumniNetwork()\">Buscar red de antiguos alumnos</a>",
    "begin_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">Iniciar cursos abiertos</a>",
    "continue_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">Continuar cursos abiertos</a>",
    "review_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">Revisar cursos abiertos</a>",
    "foundations_completed": "Has completado {{foundationsPlaylistTitle}} . Puedes encontrar cursos abiertos adicionales en la pestaña <a ng-click=\"onClickCoursesTab()\">Cursos</a> .",
    "complete_first_course": "Complete el <a ng-click=\"onClickDashboard()\">primer curso</a> para probar nuestro plan de estudios interactivo y señalar motivación a nuestro equipo de admisiones.",
    "continue_courses_business_admin": "<a ng-click=\"onClickDashboard()\">Continuar con los cursos</a> para consolidar conceptos comerciales clave y señalar a nuestro equipo de admisiones que el estudiante está listo para obtener el título.",
    "continue_courses_business_analytics": "<a ng-click=\"onClickDashboard()\">Continuar con los cursos</a> para consolidar conceptos clave de análisis de negocios y señalar la preparación para el título a nuestro equipo de admisiones.",
    "continue_courses_software_engineering": "<a ng-click=\"onClickDashboard()\">Continuar con los cursos</a> para consolidar conceptos clave de ingeniería de software y señalar la preparación para el título a nuestro equipo de admisiones."
}
};window.locales.it = window.locales.it || {};window.locales.it.admissions_modals = window.locales.it.admissions_modals || {};window.locales.it.admissions_modals.application_received_modal = window.locales.it.admissions_modals.application_received_modal || {};window.locales.it.admissions_modals.application_received_modal = {...window.locales.it.admissions_modals.application_received_modal, ...{
    "we_received_your_application": "Abbiamo ricevuto la tua candidatura!",
    "need_answers": "Hai bisogno di risposte?<br/> <a ng-click=\"onClickResources()\">Sfoglia le risorse</a>",
    "need_guidance": "Hai bisogno di una guida 1:1?<br/> <a ng-click=\"scheduleAdvisorChat()\">Pianifica una chat con un consulente</a>",
    "friends_or_colleagues": "Trova amici o colleghi<br/> <a ng-click=\"onClickAlumniNetwork()\">Cerca nella rete degli ex studenti</a>",
    "begin_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">Iniziano i corsi aperti</a>",
    "continue_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">Continuano i corsi aperti</a>",
    "review_open_courses": "{{foundationsPlaylistTitle}}<br/> <a ng-click=\"onClickOpenCourses()\">Revisione dei corsi aperti</a>",
    "foundations_completed": "Hai completato {{foundationsPlaylistTitle}} . Puoi trovare altri corsi aperti nella scheda <a ng-click=\"onClickCoursesTab()\">Corsi</a> .",
    "complete_first_course": "Completa il <a ng-click=\"onClickDashboard()\">primo corso</a> per provare il nostro programma didattico interattivo e dimostrare la tua motivazione al nostro team addetto alle ammissioni.",
    "continue_courses_business_admin": "<a ng-click=\"onClickDashboard()\">Continua i corsi</a> per consolidare i concetti aziendali chiave e segnalare al nostro team addetto alle ammissioni la tua preparazione per la laurea.",
    "continue_courses_business_analytics": "<a ng-click=\"onClickDashboard()\">Continua i corsi</a> per consolidare i concetti chiave dell'analisi aziendale e segnalare al nostro team addetto alle ammissioni la tua preparazione per la laurea.",
    "continue_courses_software_engineering": "<a ng-click=\"onClickDashboard()\">Continua i corsi</a> per consolidare i concetti chiave dell'ingegneria del software e segnalare al nostro team addetto alle ammissioni la preparazione per la laurea."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.admissions_modals = window.locales.zh.admissions_modals || {};window.locales.zh.admissions_modals.application_received_modal = window.locales.zh.admissions_modals.application_received_modal || {};window.locales.zh.admissions_modals.application_received_modal = {...window.locales.zh.admissions_modals.application_received_modal, ...{
    "we_received_your_application": "我们收到了您的申请！",
    "need_answers": "需要答案吗？<br/><a ng-click=\"onClickResources()\">浏览资源</a>",
    "need_guidance": "需要一对一指导吗？<br/><a ng-click=\"scheduleAdvisorChat()\">安排顾问聊天</a>",
    "friends_or_colleagues": "寻找朋友或同事<br/><a ng-click=\"onClickAlumniNetwork()\">搜索校友网络</a>",
    "begin_open_courses": "{{foundationsPlaylistTitle}}<br/><a ng-click=\"onClickOpenCourses()\">开始开放课程</a>",
    "continue_open_courses": "{{foundationsPlaylistTitle}}<br/><a ng-click=\"onClickOpenCourses()\">继续开设课程</a>",
    "review_open_courses": "{{foundationsPlaylistTitle}}<br/><a ng-click=\"onClickOpenCourses()\">查看开放课程</a>",
    "continue_courses": "继续开设课程以巩固关键的商业概念并向我们的招生团队发出学位准备情况的信号。",
    "foundations_completed": "您已完成{{foundationsPlaylistTitle}} 。您可以在<a ng-click=\"onClickCoursesTab()\">“课程”</a>选项卡上找到其他开放课程。",
    "complete_first_course": "完成<a ng-click=\"onClickDashboard()\">第一门课程</a>，体验我们的互动课程，并向我们的招生团队表达积极性。",
    "continue_courses_business_admin": "<a ng-click=\"onClickDashboard()\">继续开设课程</a>以巩固关键的商业概念并向我们的招生团队发出学位准备情况的信号。",
    "continue_courses_business_analytics": "<a ng-click=\"onClickDashboard()\">继续开设课程</a>以巩固关键的商业分析概念，并向我们的招生团队发出学位准备情况的信号。",
    "continue_courses_software_engineering": "<a ng-click=\"onClickDashboard()\">继续开设课程</a>以巩固关键的软件工程概念并向我们的招生团队发出学位准备情况的信号。"
}
};