window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.authentication = window.locales.am.authentication || {};window.locales.am.authentication.login_register_footer = window.locales.am.authentication.login_register_footer || {};window.locales.am.authentication.login_register_footer = {...window.locales.am.authentication.login_register_footer, ...{
    "get_help": "እርዳታ ያግኙ ወይም በ<a ng-click=\"loadFaqUrl('/help', '_blank')\" name=\"support\">{{brandName}}ድጋፍ</a> ውስጥ ያግኙን።",           
    "simply_signout": "ወይም በቀላሉ <a ng-click=\"signOut()\" name=\"sign-out\">ዘግተው ይውጡ</a>።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.authentication = window.locales.ar.authentication || {};window.locales.ar.authentication.login_register_footer = window.locales.ar.authentication.login_register_footer || {};window.locales.ar.authentication.login_register_footer = {...window.locales.ar.authentication.login_register_footer, ...{
    "simply_signout": "أو ببساطة قُم بـ <a ng-click=\"signOut()\" name=\"sign-out\">تسجيل الخروج.</a>",           
    "get_help": "احصل على المساعدة أو اتصل بنا على <a ng-click=\"loadFaqUrl('/help', '_blank')\" name=\"support\">{{brandName}} الدعم.</a>"
}
};window.locales.en = window.locales.en || {};window.locales.en.authentication = window.locales.en.authentication || {};window.locales.en.authentication.login_register_footer = window.locales.en.authentication.login_register_footer || {};window.locales.en.authentication.login_register_footer = {...window.locales.en.authentication.login_register_footer, ...{
    "get_help": "Get help or contact us in <a ng-click=\"loadFaqUrl('/help', '_blank')\" name=\"support\">{{brandName}} Support.</a>",
    "simply_signout": "Or simply <a ng-click=\"signOut()\" name=\"sign-out\">sign out.</a>"
}
};window.locales.es = window.locales.es || {};window.locales.es.authentication = window.locales.es.authentication || {};window.locales.es.authentication.login_register_footer = window.locales.es.authentication.login_register_footer || {};window.locales.es.authentication.login_register_footer = {...window.locales.es.authentication.login_register_footer, ...{
    "simply_signout": "O simplemente <a ng-click=\"signOut()\" name=\"sign-out\">desconéctate.</a>",           
    "get_help": "Obtenga ayuda o contáctenos en <a ng-click=\"loadFaqUrl('/help', '_blank')\" name=\"support\">{{brandName}} Soporte.</a>"
}
};window.locales.it = window.locales.it || {};window.locales.it.authentication = window.locales.it.authentication || {};window.locales.it.authentication.login_register_footer = window.locales.it.authentication.login_register_footer || {};window.locales.it.authentication.login_register_footer = {...window.locales.it.authentication.login_register_footer, ...{
    "simply_signout": "O <a ng-click=\"signOut()\" name=\"sign-out\">esci</a> semplicemente.",           
    "get_help": "Chiedi aiuto o contattaci nel <a ng-click=\"loadFaqUrl('/help', '_blank')\" name=\"support\">supporto {{brandName}} .</a>"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.authentication = window.locales.zh.authentication || {};window.locales.zh.authentication.login_register_footer = window.locales.zh.authentication.login_register_footer || {};window.locales.zh.authentication.login_register_footer = {...window.locales.zh.authentication.login_register_footer, ...{
    "simply_signout": "或者直接<a ng-click=\"signOut()\" name=\"sign-out\">注销</a>。",           
    "get_help": "通过<a ng-click=\"loadFaqUrl('/help', '_blank')\" name=\"support\">{{brandName}}支持板块</a>获得帮助或联系我们。"           
}
};