window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.authentication = window.locales.am.authentication || {};window.locales.am.authentication.validation_responder = window.locales.am.authentication.validation_responder || {};window.locales.am.authentication.validation_responder = {...window.locales.am.authentication.validation_responder, ...{
    "you_have_stored_data_for_another_user": "ለ {{email}} ውሂብ አከማችተዋል።  ውሂብዎን በሰርቨሩ ላይ ለማስቀመጥ እባክዎ እንደዚያ ተጠቃሚ ይግቡ።",           
    "confirm_branding_redirect": "እንደ {{userBrandName}} ተጠቃሚ አሁን ወደ {{userBrandName}} መለያዎ ይመራሉ።",
    "continue": "ቀጥል",
    "confirm_branding_redirect_contact_to_switch_institutions": "እንደ {{userBrandName}} ተጠቃሚ አሁን ወደ {{userBrandName}} መለያዎ ይመራሉ። ወደ {{authPageBrandName}} መቀየር ከፈለጉ፣ እባክዎን <a href=\"mailto:{{authPageBrandSupportEmail}}\" target=\"_blank\">{{authPageBrandSupportEmail}}</a> ን ያግኙ።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.authentication = window.locales.ar.authentication || {};window.locales.ar.authentication.validation_responder = window.locales.ar.authentication.validation_responder || {};window.locales.ar.authentication.validation_responder = {...window.locales.ar.authentication.validation_responder, ...{
    "you_have_stored_data_for_another_user": "لقد قمت بتخزين بيانات {{email}} . يرجى تسجيل الدخول باسم هذا المستخدم من أجل حفظ البيانات الخاصة بك إلى الخادم.",
    "confirm_branding_redirect": "بصفتك مستخدم {{userBrandName}} ، سيتم توجيهك الآن إلى حساب {{userBrandName}} الخاص بك.",
    "continue": "يكمل",
    "confirm_branding_redirect_contact_to_switch_institutions": "بصفتك مستخدم {{userBrandName}} ، سيتم توجيهك الآن إلى حساب {{userBrandName}} الخاص بك. إذا كنت ترغب في التبديل إلى {{authPageBrandName}} ، فيرجى الاتصال بـ <a href=\"mailto:{{authPageBrandSupportEmail}}\" target=\"_blank\">{{authPageBrandSupportEmail}}</a> ."
}
};window.locales.en = window.locales.en || {};window.locales.en.authentication = window.locales.en.authentication || {};window.locales.en.authentication.validation_responder = window.locales.en.authentication.validation_responder || {};window.locales.en.authentication.validation_responder = {...window.locales.en.authentication.validation_responder, ...{
    "you_have_stored_data_for_another_user": "You have stored data for {{email}}.  Please login as that user in order to save your data to the server.",
    "confirm_branding_redirect": "As a {{userBrandName}} user, you will now be directed to your {{userBrandName}} account.",
    "confirm_branding_redirect_contact_to_switch_institutions": "As a {{userBrandName}} user, you will now be directed to your {{userBrandName}} account. If you'd like to switch to {{authPageBrandName}}, please contact <a href=\"mailto:{{authPageBrandSupportEmail}}\" target=\"_blank\">{{authPageBrandSupportEmail}}</a>.",
    "continue": "Continue"
}
};window.locales.es = window.locales.es || {};window.locales.es.authentication = window.locales.es.authentication || {};window.locales.es.authentication.validation_responder = window.locales.es.authentication.validation_responder || {};window.locales.es.authentication.validation_responder = {...window.locales.es.authentication.validation_responder, ...{
    "you_have_stored_data_for_another_user": "Has almacenado datos para {{email}} . Inicie sesión como ese usuario para guardar sus datos en el servidor.",
    "confirm_branding_redirect": "Como usuario de {{userBrandName}} , ahora será dirigido a su cuenta de {{userBrandName}} .",
    "continue": "Continuar",
    "confirm_branding_redirect_contact_to_switch_institutions": "Como usuario de {{userBrandName}} , ahora será dirigido a su cuenta de {{userBrandName}} . Si desea cambiar a {{authPageBrandName}} , comuníquese con <a href=\"mailto:{{authPageBrandSupportEmail}}\" target=\"_blank\">{{authPageBrandSupportEmail}}</a> ."
}
};window.locales.it = window.locales.it || {};window.locales.it.authentication = window.locales.it.authentication || {};window.locales.it.authentication.validation_responder = window.locales.it.authentication.validation_responder || {};window.locales.it.authentication.validation_responder = {...window.locales.it.authentication.validation_responder, ...{
    "you_have_stored_data_for_another_user": "Hai archiviato dati per {{email}} . Effettua il login come tale utente per salvare i tuoi dati sul server.",
    "confirm_branding_redirect": "Come utente {{userBrandName}} , ora verrai indirizzato al tuo account {{userBrandName}} .",
    "continue": "Continua",
    "confirm_branding_redirect_contact_to_switch_institutions": "In qualità di utente {{userBrandName}} , ora verrai indirizzato al tuo account {{userBrandName}} . Se desideri passare a {{authPageBrandName}} , contatta <a href=\"mailto:{{authPageBrandSupportEmail}}\" target=\"_blank\">{{authPageBrandSupportEmail}}</a> ."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.authentication = window.locales.zh.authentication || {};window.locales.zh.authentication.validation_responder = window.locales.zh.authentication.validation_responder || {};window.locales.zh.authentication.validation_responder = {...window.locales.zh.authentication.validation_responder, ...{
    "you_have_stored_data_for_another_user": "你已经存储了{{email}}的数据。  请以该用户身份登录，以便将你的数据保存到服务器。",           
    "confirm_branding_redirect": "作为{{userBrandName}}用户，您现在将被定向到您的{{userBrandName}}帐户。",
    "continue": "继续",
    "confirm_branding_redirect_contact_to_switch_institutions": "作为{{userBrandName}}用户，您现在将被定向到您的{{userBrandName}}帐户。如果您想切换到{{authPageBrandName}} ，请联系<a href=\"mailto:{{authPageBrandSupportEmail}}\" target=\"_blank\">{{authPageBrandSupportEmail}}</a> 。"
}
};