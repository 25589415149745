window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.authentication = window.locales.am.authentication || {};window.locales.am.authentication.sign_up_sidebars = window.locales.am.authentication.sign_up_sidebars || {};window.locales.am.authentication.sign_up_sidebars = {...window.locales.am.authentication.sign_up_sidebars, ...{
    "demo_access": "ልዩ የ <strong>7-ደቂቃ</strong> ትምህርቶቻችንን ይለማመዱ።",           
    "demo_fastest": "በጣም <strong>ፈጣን</strong> እና እጅግ <strong>አሳታፊ</strong> የመማር ልምድ ማግኘት ይችላሉ።",           
    "demo_lessons": "የቡድንዎን ችሎታዎች በፍጥነት ለማሳደግ የቀረቡ <strong>በመቶዎች የሚቆጠሩ ትምህርቶች</strong>።",           
    "horn_quote": "&ldquo;በትምህርት አለም በቆየሁባቸው 10 አመታት ውስጥ፣ {{brandName}} ካየሁትን ማንኛውንም ነገር የላቀ <strong>ምርጡን፣ በጣም አሳታፊ የመማር ልምድን</strong> አቅርቧል... የማይታመን አዲስ አቅም ያለው ነው።&rdquo;",           
    "horn_title": "ተባባሪ መስራች፣ <br> ክሌይተን ክሪስቴንሰን የፈጠራ ተቋም",           
    "loukakos_quote": "&ldquo;በ {{brandName}} ውስጥ ያለው ተሰጥኦ ታላቅ ነው - የብልህነት፣ ብዝሃነት እና የንግድ አዋቂነት አስደናቂ ስብስብ ነው... ከፍተኛ አፈጻጸም ያለው ቡድን መገንባት ምን ያህል ቀላል እንደሚያደርግ የሚወደድ ነው።&rdquo;",           
    "loukakos_title": "በፌስቡክ ዳይሬክተር",           
    "hundreds_of_lessons": "የቢዝነስ ችሎታዎች <strong>በፍጥነት</strong> ለማሳደግ የቀረቡ በመቶዎች የሚቆጠሩ ትምህርቶች።",           
    "key_terms": "የቁልፍ ቃላት መዝገበ ቃላት እና ማጣቀሻ።",           
    "mba_in_hand": "አሁን ጀምር። ፈጣን እና ቀላል ነው መለያዎን ከፈጠሩ በኋላ ማመልከቻዎን ጨርሰው በሙሉ የ MBA ስርዓተ ትምህርታችን ወዲያው መማር ይጀምራሉ። መልካም እድል!",           
    "seamless_access": "በ <strong>ማንኛውም መሳሪያ</strong> ላይ እንከን የለሽ መዳረሻ።",           
    "mba_is_free": "የ {{brandName}} MBA ነፃ ነው።",           
    "unlimited_access": "ወደ ሙሉ ኮርስ ቤተ-መፅሀፍታችን <strong>ያልተገደበ መዳረሻ</strong>።",           
    "employer_free": "ለመመዝገብ፣ ማሰስ፣ መገናኘት እና ቃለ መጠይቅ ለማድረግ ነፃ ነው!",           
    "employer_free_beta": "ሙከራ ላይ እያለን ለመመዝገብ፣ ማሰስ፣ ቃለ መጠይቅ ለማድረግ እና ለመቅጠር ነፃ ነው!"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.authentication = window.locales.ar.authentication || {};window.locales.ar.authentication.sign_up_sidebars = window.locales.ar.authentication.sign_up_sidebars || {};window.locales.ar.authentication.sign_up_sidebars = {...window.locales.ar.authentication.sign_up_sidebars, ...{
    "demo_access": "قُم بتجربة دروسنا ذات <strong>7 دقائق</strong> الفريدة من نوعها.",           
    "demo_fastest": "<strong>أسرع</strong> تجربة تعليمية متاحة وأكثرها <strong>جاذبيةً</strong>.",           
    "demo_lessons": "<strong>المئات من الدروس</strong> لتطوير مهارات فريقك سريعاً.",           
    "horn_title": "المؤسس الشريك، <br> معهد ابتكار كلايتون كريستنسن",           
    "loukakos_title": "مدير في فيس بوك",           
    "hundreds_of_lessons": "المئات من الدروس لتطوير مهارات عملك <strong>سريعاً</strong>.",           
    "key_terms": "المصطلحات والمراجع الهامة.",           
    "mba_in_hand": "لتبدأ الآن. إنه سريع وسهل. بعد إنشاء حسابك، سوف تنهي طلبك وسوف تبدأ في التعلم على الفور من خلال مناهجنا الدراسية الكاملة في MBA. حظاً طيباً!",           
    "seamless_access": "الدخول السلس من على <strong>أي جهاز</strong>.",           
    "employer_free": "التسجيل فيه مجاني، تصفح، وقُم بإجراء مقابلات!",           
    "employer_free_beta": "التسجيل فيه مجاني، تصفح، وقُم بإجراء مقابلات وقُم بالتوظيف من خلال الإصدار التجريبي!",           
    "mba_is_free": "ماجستير في إدارة الأعمال {{brandName}} مجاني",
    "horn_quote": "\"في السنوات العشر التي قضيتها في عالم التعليم ، يقدم {{brandName}} <strong>أفضل تجربة تعليمية جذابة</strong> لأي شيء رأيته ... إمكانات {{brandName}} .\"",
    "loukakos_quote": "\"الموهبة في {{brandName}} رائعة - تركيز رائع من الذكاء والتنوع والدهاء التجاري ... أحب كم هو سهل يجعل بناء فريق أفضل أداء.\"",
    "unlimited_access": "<strong>وصول غير محدود</strong> إلى مكتبة الدورة الكاملة لدينا."
}
};window.locales.en = window.locales.en || {};window.locales.en.authentication = window.locales.en.authentication || {};window.locales.en.authentication.sign_up_sidebars = window.locales.en.authentication.sign_up_sidebars || {};window.locales.en.authentication.sign_up_sidebars = {...window.locales.en.authentication.sign_up_sidebars, ...{
    "demo_access": "Experience our unique <strong>7-minute</strong> lessons.",
    "demo_fastest": "The <strong>fastest</strong> and most <strong>engaging</strong> learning experience available.",
    "demo_lessons": "<strong>Hundreds of lessons</strong> to advance your team's skills fast.",
    "horn_quote": "&ldquo;In my 10 years in the education world, {{brandName}} offers the <strong>best, most engaging learning experience</strong> of anything I've seen...incredible disruptive potential.&rdquo;",
    "horn_title": "Co-Founder, <br> Clayton Christensen Innovation Institute",
    "loukakos_quote": "&ldquo;The talent in {{brandName}} is great - a remarkable concentration of intelligence, diversity, and business savvy...love how easy it makes building a top performing team.&rdquo;",
    "loukakos_title": "Director at Facebook",
    "hundreds_of_lessons": "Hundreds of lessons to advance your business skills <strong>fast</strong>.",
    "key_terms": "Key terms glossary and reference.",
    "mba_in_hand": "Get started now. It’s fast and easy. After creating your account, you’ll finish your application and start learning right away with our full MBA curriculum. Good luck!",
    "seamless_access": "Seamless access on <strong>any device</strong>.",
    "mba_is_free": "The {{brandName}} MBA is Free",
    "unlimited_access": "<strong>Unlimited access</strong> to our full course library.",
    "employer_free": "It's free to sign up, browse, connect and interview!",
    "employer_free_beta": "It's free to sign up, browse, interview and hire while we're in beta!"
}
};window.locales.es = window.locales.es || {};window.locales.es.authentication = window.locales.es.authentication || {};window.locales.es.authentication.sign_up_sidebars = window.locales.es.authentication.sign_up_sidebars || {};window.locales.es.authentication.sign_up_sidebars = {...window.locales.es.authentication.sign_up_sidebars, ...{
    "demo_access": "Experimente nuestras clases únicas de <strong>7 minutos</strong> .",           
    "demo_fastest": "La <strong>más rápida</strong> y <strong>cautivadora</strong> experiencia de aprendizaje disponible.",           
    "demo_lessons": "<strong>Cientos de lecciones</strong> para mejorar las habilidades de su equipo rápidamente.",           
    "horn_title": "Cofundador, <br> Instituto de innovación Clayton Christensen",           
    "hundreds_of_lessons": "Cientos de lecciones para mejorar tus habilidades empresariales <strong>rápidamente</strong>.",           
    "key_terms": "Glosario de términos principales y referencia.",           
    "mba_in_hand": "Empieza ahora. Es rápido y fácil. Después de crear tu cuenta, terminarás tu solicitud y empezarás a aprender de inmediato con nuestro plan de estudios de MBA completo. ¡Buena suerte!",           
    "seamless_access": "Accede sin problemas en <strong>cualquier dispositivo</strong>.",           
    "employer_free_beta": "¡No dude en inscribirse, navegar, entrevistar y contratar mientras estamos en beta!",           
    "loukakos_title": "Director en Facebook",           
    "employer_free": "¡No dude en inscribirse, navegar, conectarse y entrevistar!",           
    "mba_is_free": "El {{brandName}} MBA es gratuito",
    "horn_quote": "\"En mis 10 años en el mundo de la educación, {{brandName}} ofrece la <strong>mejor y más atractiva experiencia</strong> de <strong>aprendizaje</strong> de todo lo que he visto ... un potencial disruptivo increíble\".",
    "loukakos_quote": "\"El talento en {{brandName}} es excelente: una notable concentración de inteligencia, diversidad y conocimiento de los negocios ... me encanta lo fácil que es crear un equipo de alto rendimiento\".",
    "unlimited_access": "<strong>Acceso ilimitado</strong> a nuestra biblioteca de cursos completa."
}
};window.locales.it = window.locales.it || {};window.locales.it.authentication = window.locales.it.authentication || {};window.locales.it.authentication.sign_up_sidebars = window.locales.it.authentication.sign_up_sidebars || {};window.locales.it.authentication.sign_up_sidebars = {...window.locales.it.authentication.sign_up_sidebars, ...{
    "demo_access": "Prova le nostre esclusive lezioni di <strong>7 minuti</strong> .",           
    "demo_fastest": "L'esperienza di apprendimento più <strong>rapida</strong> e <strong>avvincente</strong> disponibile sul mercato.",           
    "demo_lessons": "<strong>Centinaia di lezioni</strong> per migliorare rapidamente le competenze del tuo team.",           
    "horn_title": "Cofondatore, <br> Clayton Christensen Innovation Institute",           
    "loukakos_title": "Direttore presso Facebook",           
    "hundreds_of_lessons": "Centinaia di lezioni per migliorare <strong>rapidamente</strong> le tue competenze d'impresa.",           
    "key_terms": "Glossario e riferimenti per le parole chiave.",           
    "mba_in_hand": "Parti subito. È facile e veloce. Dopo aver creato il tuo account, completerai la tua candidatura e comincerai immediatamente a imparare con il nostro curriculum MBA completo. Buona fortuna!",           
    "seamless_access": "Accesso uniforme su <strong>tutti i dispositivi</strong>.",           
    "employer_free": "Registri, sfogli, contatti e intervisti gratis!",           
    "employer_free_beta": "Finché siamo in beta registri, sfogli, contatti, intervisti e assumi gratis!",           
    "mba_is_free": "L'MBA {{brandName}} è gratuito",
    "horn_quote": "\"Nei miei 10 anni nel mondo dell'istruzione, {{brandName}} offre la <strong>migliore e più coinvolgente esperienza</strong> di <strong>apprendimento</strong> di tutto ciò che ho visto ... incredibile potenziale dirompente.\"",
    "loukakos_quote": "\"Il talento in {{brandName}} è eccezionale: una notevole concentrazione di intelligenza, diversità e buon senso degli affari ... adoro quanto sia facile costruire un team dalle migliori prestazioni.\"",
    "unlimited_access": "<strong>Accesso illimitato</strong> alla nostra biblioteca completa dei corsi."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.authentication = window.locales.zh.authentication || {};window.locales.zh.authentication.sign_up_sidebars = window.locales.zh.authentication.sign_up_sidebars || {};window.locales.zh.authentication.sign_up_sidebars = {...window.locales.zh.authentication.sign_up_sidebars, ...{
    "demo_access": "体验我们特有的 <strong>7 分钟</strong>课程。",           
    "demo_fastest": "提供<strong>最快</strong>、最<strong>吸引人</strong>的学习体验。",           
    "demo_lessons": "<strong>数百种课程</strong>快速提升您团队的技能。",           
    "horn_title": "共同创始人，<br> Clayton Christensen 创新学院",           
    "hundreds_of_lessons": "可以<strong>快速</strong>提升您的业务技能的数以百计的课业。",           
    "key_terms": "重要术语和参考资料。",           
    "mba_in_hand": "在 Smartly 网络中，您会遇到在寻找像您这样的候选者的雇主。他们付钱给 Smartly 以获得找到您的特权。这也帮助我们坚持 Smartly 向每位学员免费。",           
    "seamless_access": "在<strong>任何设备</strong>上无缝访问。",           
    "employer_free_beta": "免费注册，浏览，面试和招聘，公测版本！",           
    "loukakos_title": "总监的Facebook",           
    "employer_free": "免费注册、浏览、连接和面试！",           
    "mba_is_free": "{{brandName}}MBA课程免费",           
    "horn_quote": "&ldquo;在我的十年求学期间，{{brandName}}提供了<strong>无与伦比的卓越学习经验</strong>，具有令人难以置信的颠覆性潜力。&rdquo;",           
    "loukakos_quote": "&ldquo;{{brandName}}拥有众多顶尖人才——集智慧、多样性和商业头脑于一身，随时随地即可组建一支顶尖团队。&rdquo;",           
    "unlimited_access": "<strong>可随时访问</strong>我们的全部课程图书馆。"           
}
};