window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.authentication = window.locales.am.authentication || {};window.locales.am.authentication.confirm_profile_info_form = window.locales.am.authentication.confirm_profile_info_form || {};window.locales.am.authentication.confirm_profile_info_form = {...window.locales.am.authentication.confirm_profile_info_form, ...{
    "a_server_error_occurred": "የሰርቨር ስህተት ተከስቷል። {{brandName}} መሀንዲሶች እንዲያውቁ ተደርጓል",           
    "confirm_the_following": "የሚከተለውን መረጃ ያረጋግጡ",           
    "email": "ኢሜይል",           
    "facebook": "ፌስቡክ",           
    "google_oauth2": "ጉግል",           
    "apple": "አፕል",           
    "next": "ቀጣይ",           
    "next_step": "ቀጣይ እርምጃ",           
    "school_optional": "ትምህርት ቤት (አማራጭ)",           
    "you_are_signing_up_using_x": "{{providerName}} ን በመጠቀም እየተመዘገቡ ነው"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.authentication = window.locales.ar.authentication || {};window.locales.ar.authentication.confirm_profile_info_form = window.locales.ar.authentication.confirm_profile_info_form || {};window.locales.ar.authentication.confirm_profile_info_form = {...window.locales.ar.authentication.confirm_profile_info_form, ...{
    "confirm_the_following": "تأكيد المعلومات التالية",           
    "email": "البريد الإلكتروني",           
    "facebook": "فيس بوك",           
    "google_oauth2": "جوجل",           
    "next": "التالى",           
    "next_step": "الخطوة التالية",           
    "school_optional": "المدرسة (اختياري)",           
    "you_are_signing_up_using_x": "إنك تقوم بالاشتراك باستخدام {{providerName}}",           
    "a_server_error_occurred": "حدث خطأ في الخادم. {{brandName}} تم إخطار المهندسين",
    "apple": "Apple"           
}
};window.locales.en = window.locales.en || {};window.locales.en.authentication = window.locales.en.authentication || {};window.locales.en.authentication.confirm_profile_info_form = window.locales.en.authentication.confirm_profile_info_form || {};window.locales.en.authentication.confirm_profile_info_form = {...window.locales.en.authentication.confirm_profile_info_form, ...{
    "a_server_error_occurred": "A server error occurred. {{brandName}} engineers have been notified",
    "confirm_the_following": "Confirm the following information",
    "email": "Email",
    "facebook": "Facebook",
    "google_oauth2": "Google",
    "apple": "Apple",
    "next": "Next",
    "next_step": "Next Step",
    "school_optional": "School (Optional)",
    "you_are_signing_up_using_x": "You're signing up using {{providerName}}"
}
};window.locales.es = window.locales.es || {};window.locales.es.authentication = window.locales.es.authentication || {};window.locales.es.authentication.confirm_profile_info_form = window.locales.es.authentication.confirm_profile_info_form || {};window.locales.es.authentication.confirm_profile_info_form = {...window.locales.es.authentication.confirm_profile_info_form, ...{
    "confirm_the_following": "Confirma la siguiente información",           
    "email": "Correo electrónico",           
    "facebook": "Facebook",           
    "google_oauth2": "Google",           
    "next": "Siguiente",           
    "next_step": "Próximo paso",           
    "school_optional": "Escuela (Opcional)",           
    "you_are_signing_up_using_x": "Estás registrándote usando {{providerName}}",           
    "a_server_error_occurred": "Se produjo un error en el servidor. {{brandName}} ingenieros de {{brandName}} han sido notificados",
    "apple": "Apple"           
}
};window.locales.it = window.locales.it || {};window.locales.it.authentication = window.locales.it.authentication || {};window.locales.it.authentication.confirm_profile_info_form = window.locales.it.authentication.confirm_profile_info_form || {};window.locales.it.authentication.confirm_profile_info_form = {...window.locales.it.authentication.confirm_profile_info_form, ...{
    "confirm_the_following": "Conferma le informazioni seguenti",           
    "email": "Email",           
    "facebook": "Facebook",           
    "google_oauth2": "Google",           
    "next": "Avanti",           
    "next_step": "Prossima Fase",           
    "school_optional": "Scuola (opzionale)",           
    "you_are_signing_up_using_x": "Ti stai registrando attraverso {{providerName}}",           
    "a_server_error_occurred": "Si è verificato un errore del server. {{brandName}} ingegneri {{brandName}} sono stati avvisati",
    "apple": "Apple"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.authentication = window.locales.zh.authentication || {};window.locales.zh.authentication.confirm_profile_info_form = window.locales.zh.authentication.confirm_profile_info_form || {};window.locales.zh.authentication.confirm_profile_info_form = {...window.locales.zh.authentication.confirm_profile_info_form, ...{
    "confirm_the_following": "请确认以下信息",           
    "email": "电子邮件",           
    "facebook": "Facebook",           
    "google_oauth2": "Google",           
    "next": "下一个",           
    "next_step": "下一步",           
    "school_optional": "学校（可选）",           
    "you_are_signing_up_using_x": "您正在使用 {{providerName}} 注册",           
    "a_server_error_occurred": "服务器错误。已通知{{brandName}}工程师",           
    "apple": "Apple"           
}
};