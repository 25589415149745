window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.authentication = window.locales.am.authentication || {};window.locales.am.authentication.sign_in_form = window.locales.am.authentication.sign_in_form || {};window.locales.am.authentication.sign_in_form = {...window.locales.am.authentication.sign_in_form, ...{
    "email_address": "የኢሜይል አድራሻ",           
    "phone": "ስልክ ቁጥር",           
    "facebook": "በፌስቡክ ይግቡ",           
    "forgot_your_password": "የይለፍ ቃልዎን ረሱት?",           
    "google": "በጉግል ይግቡ",           
    "login": "ይግቡ",           
    "login_to_your_account": "የሚከተለውን በመጠቀም ወደ መለያዎ ይግቡ",           
    "or_enter_the_info": "ወይም ከታች መረጃውን ያስገቡ",           
    "password": "የይለፍ ቃል",           
    "apple": "በአፕል ይግቡ",           
    "wechat": "በ Wechat ይግቡ",           
    "log_in": "ግባ",
    "login_apple": "ከአፕል ጋር ይግቡ",
    "login_wechat": "በWeChat ይግቡ",
    "login_facebook": "በፌስቡክ ይግቡ",
    "login_google": "በGoogle ይግቡ",
    "or": "ወይም",
    "enter_your_email": "የእርስዎን ኢሜይል ያስገቡ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.authentication = window.locales.ar.authentication || {};window.locales.ar.authentication.sign_in_form = window.locales.ar.authentication.sign_in_form || {};window.locales.ar.authentication.sign_in_form = {...window.locales.ar.authentication.sign_in_form, ...{
    "email_address": "عنوان البريد الإلكتروني",           
    "phone": "رقم الهاتف",           
    "forgot_your_password": "هل نسيت كلمة المرور الخاصة بك؟",           
    "login": "تسجيل الدخول",           
    "login_to_your_account": "قُم بتسجيل الدخول إلى حسابك باستخدام",           
    "or_enter_the_info": "أو أدخل المعلومات أدناه",           
    "password": "كلمة المرور",           
    "apple": "تسجيل الدخول باستخدام Apple",
    "facebook": "قم بتسجيل الدخول باستخدام الفيسبوك",
    "google": "الدخول مع جوجل",
    "wechat": "سجّل الدخول باستخدام WeChat",
    "log_in": "تسجيل الدخول",
    "login_apple": "تسجيل الدخول مع Apple",
    "login_wechat": "تسجيل الدخول باستخدام WeChat",
    "login_facebook": "تسجيل الدخول باستخدام الفيسبوك",
    "login_google": "تسجيل الدخول عبر جوجل",
    "or": "أو",
    "enter_your_email": "أدخل بريدك الإلكتروني"
}
};window.locales.en = window.locales.en || {};window.locales.en.authentication = window.locales.en.authentication || {};window.locales.en.authentication.sign_in_form = window.locales.en.authentication.sign_in_form || {};window.locales.en.authentication.sign_in_form = {...window.locales.en.authentication.sign_in_form, ...{
    "email_address": "Email Address",
    "phone": "Phone Number",
    "facebook": "Sign in with Facebook",
    "forgot_your_password": "Forgot your password?",
    "google": "Sign in with Google",
    "login": "Login",
    "log_in": "Log In",
    "login_to_your_account": "Login to your account using",
    "or_enter_the_info": "Or enter the information below",
    "password": "Password",
    "apple": "Sign in with Apple",
    "wechat": "Sign in with Wechat",
    "login_apple": "Log In With Apple",
    "login_wechat": "Log In With WeChat",
    "login_facebook": "Log In With Facebook",
    "login_google": "Log In With Google",
    "or": "or",
    "enter_your_email": "Enter Your E-mail"
}
};window.locales.es = window.locales.es || {};window.locales.es.authentication = window.locales.es.authentication || {};window.locales.es.authentication.sign_in_form = window.locales.es.authentication.sign_in_form || {};window.locales.es.authentication.sign_in_form = {...window.locales.es.authentication.sign_in_form, ...{
    "email_address": "Dirección de correo electrónico",           
    "forgot_your_password": "¿Olvidaste tu contraseña?",           
    "login": "Iniciar sesión",           
    "login_to_your_account": "Inicia sesión en tu cuenta a través de",           
    "or_enter_the_info": "O introduce la siguiente información",           
    "phone": "Número de teléfono",           
    "password": "Contraseña",           
    "apple": "Inicia sesión con Apple",
    "facebook": "Iniciar sesión usando Facebook",
    "google": "Inicia sesión con Google",
    "wechat": "Iniciar sesión con WeChat",
    "log_in": "Iniciar sesión",
    "login_apple": "Iniciar sesión con Apple",
    "login_wechat": "Iniciar sesión con WeChat",
    "login_facebook": "Iniciar sesión con Facebook",
    "login_google": "Iniciar sesión con Google",
    "or": "o",
    "enter_your_email": "Introduce tu correo electrónico"
}
};window.locales.it = window.locales.it || {};window.locales.it.authentication = window.locales.it.authentication || {};window.locales.it.authentication.sign_in_form = window.locales.it.authentication.sign_in_form || {};window.locales.it.authentication.sign_in_form = {...window.locales.it.authentication.sign_in_form, ...{
    "email_address": "Indirizzo email",           
    "phone": "Numero di telefono",           
    "forgot_your_password": "Password dimenticata?",           
    "login": "Accesso",           
    "login_to_your_account": "Accedi al tuo account usando",           
    "or_enter_the_info": "O inserisci le informazioni in basso",           
    "password": "Password",           
    "apple": "Accedi con Apple",
    "facebook": "Accedi con Facebook",
    "google": "Accedi con Google",
    "wechat": "Accedi con WeChat",
    "log_in": "Accesso",
    "login_apple": "Accedi con Apple",
    "login_wechat": "Accedi con WeChat",
    "login_facebook": "Fai il login con facebook",
    "login_google": "Accedi con Google",
    "or": "o",
    "enter_your_email": "Inserisci il tuo indirizzo email"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.authentication = window.locales.zh.authentication || {};window.locales.zh.authentication.sign_in_form = window.locales.zh.authentication.sign_in_form || {};window.locales.zh.authentication.sign_in_form = {...window.locales.zh.authentication.sign_in_form, ...{
    "email": "电子邮件地址",           
    "forgot_your_password": "忘记了您的密码？",           
    "login": "登录",           
    "login_to_your_account": "登录您的帐户，使用",           
    "or_enter_the_info": "或者在下面输入信息",           
    "phone": "电话号码",           
    "email_address": "电子邮件地址",           
    "password": "密码",           
    "apple": "用Apple账号登录",           
    "facebook": "用Facebook账号登录",           
    "google": "用Google账号登录",           
    "wechat": "使用微信登录",           
    "log_in": "登录",
    "login_apple": "使用 Apple 登录",
    "login_wechat": "微信登录",
    "login_facebook": "使用 Facebook 登录",
    "login_google": "使用谷歌登录",
    "or": "或者",
    "enter_your_email": "输入你的电子邮箱"
}
};