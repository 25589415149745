window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.add_to_calendar_widget = window.locales.am.add_to_calendar_widget || {};window.locales.am.add_to_calendar_widget.add_to_calendar_widget = window.locales.am.add_to_calendar_widget.add_to_calendar_widget || {};window.locales.am.add_to_calendar_widget.add_to_calendar_widget = {...window.locales.am.add_to_calendar_widget.add_to_calendar_widget, ...{
    "add_to_calendar": "ወደ ቀን መቁጠሪያ አክል",           
    "add_to_calendar_select": "ወደ ቀን መቁጠሪያ አክል ▾",           
    "download": "አውርድ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.add_to_calendar_widget = window.locales.ar.add_to_calendar_widget || {};window.locales.ar.add_to_calendar_widget.add_to_calendar_widget = window.locales.ar.add_to_calendar_widget.add_to_calendar_widget || {};window.locales.ar.add_to_calendar_widget.add_to_calendar_widget = {...window.locales.ar.add_to_calendar_widget.add_to_calendar_widget, ...{
    "add_to_calendar": "إضافة إلى التقويم",
    "add_to_calendar_select": "أضف إلى التقويم ▾",
    "download": "تحميل"
}
};window.locales.en = window.locales.en || {};window.locales.en.add_to_calendar_widget = window.locales.en.add_to_calendar_widget || {};window.locales.en.add_to_calendar_widget.add_to_calendar_widget = window.locales.en.add_to_calendar_widget.add_to_calendar_widget || {};window.locales.en.add_to_calendar_widget.add_to_calendar_widget = {...window.locales.en.add_to_calendar_widget.add_to_calendar_widget, ...{
    "add_to_calendar": "Add to Calendar",
    "add_to_calendar_select": "Add to calendar ▾",
    "download": "Download"
}
};window.locales.es = window.locales.es || {};window.locales.es.add_to_calendar_widget = window.locales.es.add_to_calendar_widget || {};window.locales.es.add_to_calendar_widget.add_to_calendar_widget = window.locales.es.add_to_calendar_widget.add_to_calendar_widget || {};window.locales.es.add_to_calendar_widget.add_to_calendar_widget = {...window.locales.es.add_to_calendar_widget.add_to_calendar_widget, ...{
    "add_to_calendar": "Añadir al calendario",
    "add_to_calendar_select": "Añadir al calendario ▾",
    "download": "Descargar"
}
};window.locales.it = window.locales.it || {};window.locales.it.add_to_calendar_widget = window.locales.it.add_to_calendar_widget || {};window.locales.it.add_to_calendar_widget.add_to_calendar_widget = window.locales.it.add_to_calendar_widget.add_to_calendar_widget || {};window.locales.it.add_to_calendar_widget.add_to_calendar_widget = {...window.locales.it.add_to_calendar_widget.add_to_calendar_widget, ...{
    "add_to_calendar": "Aggiungi al calendario",
    "add_to_calendar_select": "Aggiungi al calendario ▾",
    "download": "Scaricare"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.add_to_calendar_widget = window.locales.zh.add_to_calendar_widget || {};window.locales.zh.add_to_calendar_widget.add_to_calendar_widget = window.locales.zh.add_to_calendar_widget.add_to_calendar_widget || {};window.locales.zh.add_to_calendar_widget.add_to_calendar_widget = {...window.locales.zh.add_to_calendar_widget.add_to_calendar_widget, ...{
    "add_to_calendar": "添加至日历",           
    "add_to_calendar_select": "添加至日历 ▾",           
    "download": "下载"           
}
};