import { angular2react } from 'angular2react';
import { type auto, type IComponentOptions } from 'angular';
import { type AnyObject } from '@Types';
// https://github.com/bcherny/angular2react-demos/blob/master/multi-file/src/lazyInjector.js

/**
 * Angular2react needs an $injector, but it doesn't actually invoke $injector.get
 * until we invoke ReactDOM.render. We can take advantage of this to provide the
 * "lazy" injector below to React components created with angular2react, as a way
 * to avoid component ordering issues.
 *
 * @see https://github.com/coatue-oss/angular2react/issues/12
 */

// state

function getLazyInjector() {
    let $injector: auto.IInjectorService;

    const lazyInjector = {
        get $injector() {
            return {
                get get() {
                    return $injector.get;
                },
            };
        },
        set $injector(_$injector) {
            $injector = _$injector as auto.IInjectorService;
        },
    };
    return lazyInjector;
}

export function angularDirectiveToReact<T extends AnyObject = AnyObject>(
    moduleName: string,
    directiveName: string,
    directiveDefinitionOrBindings: AnyObject,
) {
    const bindings = directiveDefinitionOrBindings.scope || directiveDefinitionOrBindings;
    const mockComponent = {
        bindings,
    } as IComponentOptions;
    const lazyInjector = getLazyInjector();

    // Since angularDirectiveToReact is included at the top level of files, it can cause things
    // to blow up if a module is not available. When we're using angular.mock.module, even if you've
    // imported the module file the module still might not be available. To see this break, remove
    // this try/catch and run feedback_modal_spec.js
    let module;
    try {
        module = angular.module(moduleName);
    } catch (e) {
        if (window.RUNNING_IN_TEST_MODE) {
            return () => `${moduleName} is not available so ${directiveName} will not be rendered`;
        }
        throw e;
    }

    module.run([
        '$injector',
        (_$injector: auto.IInjectorService) => {
            lazyInjector.$injector = _$injector;
        },
    ]);

    return angular2react<T>(directiveName, mockComponent, lazyInjector.$injector as auto.IInjectorService);
}

export default angularDirectiveToReact;
