window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.authentication = window.locales.am.authentication || {};window.locales.am.authentication.auth_form_helper_mixin = window.locales.am.authentication.auth_form_helper_mixin || {};window.locales.am.authentication.auth_form_helper_mixin = {...window.locales.am.authentication.auth_form_helper_mixin, ...{
    "server_error": "የሰርቨር ስህተት",           
    "error_account_deactivated": "የእርስዎ መለያ ተዘግቷል። እንደገና ለማንቃት ከፈለጉ <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> ን ያነጋግሩ።",           
    "please_install_wechat": "እባክዎ WeChatን ይጫኑ",           
    "error_account_in_deletion_queue": "መለያዎ ለመሰረዝ መርሐግብር ተይዞለታል። ለበለጠ መረጃ <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> ን ያነጋግሩ።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.authentication = window.locales.ar.authentication || {};window.locales.ar.authentication.auth_form_helper_mixin = window.locales.ar.authentication.auth_form_helper_mixin || {};window.locales.ar.authentication.auth_form_helper_mixin = {...window.locales.ar.authentication.auth_form_helper_mixin, ...{
    "server_error": "خطأ في الخادم",
    "error_account_deactivated": "حسابك تم تعطيله. اتصل <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> إذا كنت ترغب في إعادة تنشيطه.",
    "please_install_wechat": "الرجاء تثبيت WeChat",
    "error_account_in_deletion_queue": "تمت جدولة حسابك للحذف. اتصل <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> للحصول على مزيد من المعلومات."
}
};window.locales.en = window.locales.en || {};window.locales.en.authentication = window.locales.en.authentication || {};window.locales.en.authentication.auth_form_helper_mixin = window.locales.en.authentication.auth_form_helper_mixin || {};window.locales.en.authentication.auth_form_helper_mixin = {...window.locales.en.authentication.auth_form_helper_mixin, ...{
    "server_error": "Server Error",
    "error_account_deactivated": "Your account has been deactivated. Contact <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> if you wish to reactivate it.",
    "error_account_in_deletion_queue": "Your account is scheduled for deletion. Contact <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> for further information.",
    "please_install_wechat": "Please install WeChat"
}
};window.locales.es = window.locales.es || {};window.locales.es.authentication = window.locales.es.authentication || {};window.locales.es.authentication.auth_form_helper_mixin = window.locales.es.authentication.auth_form_helper_mixin || {};window.locales.es.authentication.auth_form_helper_mixin = {...window.locales.es.authentication.auth_form_helper_mixin, ...{
    "server_error": "Error del Servidor",
    "error_account_deactivated": "Tu cuenta ha sido desactivada. Póngase en contacto con <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> si desea reactivarlo.",
    "please_install_wechat": "Instale WeChat",
    "error_account_in_deletion_queue": "Su cuenta está programada para ser eliminada. Póngase en contacto <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> para obtener más información."
}
};window.locales.it = window.locales.it || {};window.locales.it.authentication = window.locales.it.authentication || {};window.locales.it.authentication.auth_form_helper_mixin = window.locales.it.authentication.auth_form_helper_mixin || {};window.locales.it.authentication.auth_form_helper_mixin = {...window.locales.it.authentication.auth_form_helper_mixin, ...{
    "server_error": "Errore server",           
    "error_account_deactivated": "Il tuo account è stato disattivato. Contatta <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> se desideri riattivarlo.",
    "please_install_wechat": "Si prega di installare WeChat",
    "error_account_in_deletion_queue": "Il tuo account è programmato per l'eliminazione. Contatta <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> per ulteriori informazioni."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.authentication = window.locales.zh.authentication || {};window.locales.zh.authentication.auth_form_helper_mixin = window.locales.zh.authentication.auth_form_helper_mixin || {};window.locales.zh.authentication.auth_form_helper_mixin = {...window.locales.zh.authentication.auth_form_helper_mixin, ...{
    "server_error": "服务器错误",           
    "error_account_deactivated": "你的帐户已被停用。如欲激活，请发送邮件至<a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>进行联系。",           
    "please_install_wechat": "请安装微信",
    "error_account_in_deletion_queue": "您的帐户已安排删除。联系<a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>了解更多信息。"
}
};