window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.authentication = window.locales.am.authentication || {};window.locales.am.authentication.sign_in = window.locales.am.authentication.sign_in || {};window.locales.am.authentication.sign_in = {...window.locales.am.authentication.sign_in, ...{
    "do_not_have_an_account": "መለያ የለዎትም?",           
    "login": "ወደ <strong>{{brandName}}</strong> ይግቡ",           
    "sign_up": "ይመዝገቡ",           
    "sign_up_to_your_account": "ወደ {{brandName}} መለያዎ ይመዝገቡ",           
    "login_to_miyamiya": "ወደ <strong>Miya Miya</strong> ይግቡ",           
    "login_to_valar": "ወደ <strong>Valar ይግቡ</strong>",
    "already_have_an_account": "መለያ አለህ?",
    "log_in": "ግባ",
    "icp_file_number": "የሻንጋይ አይሲፒ ቁጥር 2021026217-2",
    "security_filing_number": "የሻንጋይ የህዝብ ኔትወርክ ደህንነት ቁጥር 31010602006704"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.authentication = window.locales.ar.authentication || {};window.locales.ar.authentication.sign_in = window.locales.ar.authentication.sign_in || {};window.locales.ar.authentication.sign_in = {...window.locales.ar.authentication.sign_in, ...{
    "do_not_have_an_account": "أليس لديك حساب؟",           
    "sign_up": "الاشتراك",           
    "login_to_miyamiya": "تسجيل الدخول إلى <strong>مية مية</strong>",
    "login": "تسجيل الدخول إلى <strong>{{brandName}}</strong>",
    "sign_up_to_your_account": "{{brandName}} حسابك {{brandName}}",
    "login_to_valar": "تسجيل الدخول إلى <strong>Valar</strong>",
    "already_have_an_account": "هل لديك حساب؟",
    "log_in": "تسجيل الدخول",
    "icp_file_number": "شنغهاي ICP رقم 2021026217-2",
    "security_filing_number": "Shanghai Public Network Security No. 31010602006704"
}
};window.locales.en = window.locales.en || {};window.locales.en.authentication = window.locales.en.authentication || {};window.locales.en.authentication.sign_in = window.locales.en.authentication.sign_in || {};window.locales.en.authentication.sign_in = {...window.locales.en.authentication.sign_in, ...{
    "do_not_have_an_account": "Don't have an account?",
    "login": "Login to <strong>{{brandName}}</strong>",
    "sign_up": "Sign Up",
    "sign_up_to_your_account": "Sign Up to your {{brandName}} account",
    "login_to_miyamiya": "Login to <strong>Miya Miya</strong>",
    "login_to_valar": "Login to <strong>Valar</strong>",
    "already_have_an_account": "Already have an account?",
    "log_in": "Log In",
    "icp_file_number": "Shanghai ICP No. 2021026217-2",
    "security_filing_number": "Shanghai Public Network Security No. 31010602006704"
}
};window.locales.es = window.locales.es || {};window.locales.es.authentication = window.locales.es.authentication || {};window.locales.es.authentication.sign_in = window.locales.es.authentication.sign_in || {};window.locales.es.authentication.sign_in = {...window.locales.es.authentication.sign_in, ...{
    "do_not_have_an_account": "¿No tienes una cuenta?",           
    "sign_up": "Regístrate",           
    "login_to_miyamiya": "Inicie sesión en <strong>Miya Miya</strong>",
    "login": "Inicie sesión en <strong>{{brandName}}</strong>",
    "sign_up_to_your_account": "Regístrese en su cuenta {{brandName}}",
    "login_to_valar": "Iniciar sesión en <strong>Valar</strong>",
    "already_have_an_account": "¿Ya tienes una cuenta?",
    "log_in": "Iniciar sesión",
    "icp_file_number": "Shanghái ICP No. 2021026217-2",
    "security_filing_number": "Seguridad de la red pública de Shanghai No. 31010602006704"
}
};window.locales.it = window.locales.it || {};window.locales.it.authentication = window.locales.it.authentication || {};window.locales.it.authentication.sign_in = window.locales.it.authentication.sign_in || {};window.locales.it.authentication.sign_in = {...window.locales.it.authentication.sign_in, ...{
    "do_not_have_an_account": "Non hai un account?",           
    "sign_up": "Registrati",           
    "login_to_miyamiya": "Accedi a <strong>Miya Miya</strong>",
    "login": "Accedi a <strong>{{brandName}}</strong>",
    "sign_up_to_your_account": "Iscriviti al tuo account {{brandName}}",
    "login_to_valar": "Accedi a <strong>Valar</strong>",
    "already_have_an_account": "Hai già un account?",
    "log_in": "Accesso",
    "icp_file_number": "Shanghai ICP n. 2021026217-2",
    "security_filing_number": "Sicurezza della rete pubblica di Shanghai n. 31010602006704"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.authentication = window.locales.zh.authentication || {};window.locales.zh.authentication.sign_in = window.locales.zh.authentication.sign_in || {};window.locales.zh.authentication.sign_in = {...window.locales.zh.authentication.sign_in, ...{
    "do_not_have_an_account": "没有帐户？",           
    "sign_up": "注册",           
    "login_to_miyamiya": "登录<strong>Miya Miya</strong>",           
    "login": "登录<strong>{{brandName}}</strong>",           
    "sign_up_to_your_account": "注册你的{{brandName}}账号",           
    "login_to_valar": "登录到<strong>Valar</strong>",
    "already_have_an_account": "已经有一个帐户？",
    "log_in": "登录",
    "icp_file_number": "沪ICP备2021026217号-2",           
    "security_filing_number": "沪公网安备31010602006704号"           
}
};