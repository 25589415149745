window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.admissions_modals = window.locales.am.admissions_modals || {};window.locales.am.admissions_modals.welcome_to_dashboard = window.locales.am.admissions_modals.welcome_to_dashboard || {};window.locales.am.admissions_modals.welcome_to_dashboard = {...window.locales.am.admissions_modals.welcome_to_dashboard, ...{
    "try_any_free_lessons": "ለመማር ዝግጁ ነዎት?<br/> <a ng-click=\"closeModal()\">የእኛን ክፍት ኮርሶች ይሞክሩ</a>.",
    "complete_your_application": "ለማመልከት ዝግጁ ነዎት?<br/> <a ng-click=\"completeApplication()\">የተሟላ ነፃ መተግበሪያ</a> ።",
    "admissions_question": "የመግቢያ ጥያቄ?<br/> <a ng-click=\"onClickAdmissionsQuestion()\">መልሶችን እዚህ ያግኙ</a> ።",
    "friends_or_colleagues": "ጓደኞች ወይስ የስራ ባልደረቦች?<br/> <a ng-click=\"onClickAlumniNetwork()\">የኛን የተመራቂዎች መረብ ፈልግ</a> ።",
    "welcome": "እንኳን ወደ {{brandName}} በደህና መጡ!",
    "need_more_info": "ተጨማሪ መረጃ ይፈልጋሉ?<br/> <a ng-click=\"onClickNeedMoreInfo()\">{{brandName}} ድር ጣቢያን ይመልከቱ</a> ።",
    "try_our_lessons": "ትምህርቶቻችንን ይሞክሩ<br /> <a ng-click=\"onClickOpenCourses()\">ናሙና ክፍት ኮርሶች</a>"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.admissions_modals = window.locales.ar.admissions_modals || {};window.locales.ar.admissions_modals.welcome_to_dashboard = window.locales.ar.admissions_modals.welcome_to_dashboard || {};window.locales.ar.admissions_modals.welcome_to_dashboard = {...window.locales.ar.admissions_modals.welcome_to_dashboard, ...{
    "try_any_free_lessons": "مستعد للتعلم؟<br/> <a ng-click=\"closeModal()\">جرب دوراتنا المفتوحة</a>.",
    "complete_your_application": "جاهز للتقديم؟<br/> <a ng-click=\"completeApplication()\">تطبيق مجاني كامل</a>.",
    "admissions_question": "سؤال القبول؟<br/> <a ng-click=\"onClickAdmissionsQuestion()\">احصل على إجابات هنا</a>.",
    "friends_or_colleagues": "أصدقاء أم زملاء؟<br/> <a ng-click=\"onClickAlumniNetwork()\">ابحث في شبكة الخريجين لدينا</a>.",
    "welcome": "مرحبًا بك في {{brandName}} !",
    "need_more_info": "بحاجة الى مزيد من المعلومات؟<br/> <a ng-click=\"onClickNeedMoreInfo()\">عرض موقع {{brandName}} على الويب</a> .",
    "try_our_lessons": "جرب دروسنا<br /> <a ng-click=\"onClickOpenCourses()\">عينة من الدورات المفتوحة</a>"
}
};window.locales.en = window.locales.en || {};window.locales.en.admissions_modals = window.locales.en.admissions_modals || {};window.locales.en.admissions_modals.welcome_to_dashboard = window.locales.en.admissions_modals.welcome_to_dashboard || {};window.locales.en.admissions_modals.welcome_to_dashboard = {...window.locales.en.admissions_modals.welcome_to_dashboard, ...{
    "welcome": "Welcome to {{brandName}}!",
    "try_any_free_lessons": "Ready to learn?<br/><a ng-click=\"onClickOpenCourses()\">Try our open courses</a>.",
    "complete_your_application": "Ready to apply?<br/><a ng-click=\"completeApplication()\">Complete free application</a>.",
    "admissions_question": "Admissions question?<br/><a ng-click=\"onClickAdmissionsQuestion()\">Get answers here</a>.",
    "friends_or_colleagues": "Friends or colleagues?<br/><a ng-click=\"onClickAlumniNetwork()\">Search our alumni network</a>.",
    "need_more_info": "Need more info?<br/><a ng-click=\"onClickNeedMoreInfo()\">View {{brandName}} website</a>.",
    "try_our_lessons": "Try our lessons<br /><a ng-click=\"onClickOpenCourses()\">Sample open courses</a>"
}
};window.locales.es = window.locales.es || {};window.locales.es.admissions_modals = window.locales.es.admissions_modals || {};window.locales.es.admissions_modals.welcome_to_dashboard = window.locales.es.admissions_modals.welcome_to_dashboard || {};window.locales.es.admissions_modals.welcome_to_dashboard = {...window.locales.es.admissions_modals.welcome_to_dashboard, ...{
    "try_any_free_lessons": "¿Listo para aprender?<br/> <a ng-click=\"closeModal()\">Prueba nuestros cursos abiertos</a>.",
    "complete_your_application": "¿Listo para aplicar?<br/> <a ng-click=\"completeApplication()\">Aplicación gratuita completa</a>.",
    "admissions_question": "¿Pregunta de admisiones?<br/> <a ng-click=\"onClickAdmissionsQuestion()\">Obtén respuestas aquí</a>.",
    "friends_or_colleagues": "¿Amigos o colegas?<br/> <a ng-click=\"onClickAlumniNetwork()\">Busca en nuestra red de antiguos alumnos</a>.",
    "welcome": "¡Bienvenido a {{brandName}} !",
    "need_more_info": "¿Necesitas más información?<br/> <a ng-click=\"onClickNeedMoreInfo()\">Ver el sitio web {{brandName}}</a> .",
    "try_our_lessons": "Prueba nuestras lecciones<br /> <a ng-click=\"onClickOpenCourses()\">Ejemplos de cursos abiertos</a>"
}
};window.locales.it = window.locales.it || {};window.locales.it.admissions_modals = window.locales.it.admissions_modals || {};window.locales.it.admissions_modals.welcome_to_dashboard = window.locales.it.admissions_modals.welcome_to_dashboard || {};window.locales.it.admissions_modals.welcome_to_dashboard = {...window.locales.it.admissions_modals.welcome_to_dashboard, ...{
    "try_any_free_lessons": "Pronto ad imparare?<br/> <a ng-click=\"closeModal()\">Prova i nostri corsi aperti</a>.",
    "complete_your_application": "Pronto per candidarti?<br/> <a ng-click=\"completeApplication()\">Applicazione gratuita completa</a>.",
    "admissions_question": "Domanda di ammissione?<br/> <a ng-click=\"onClickAdmissionsQuestion()\">Ottieni risposte qui</a>.",
    "friends_or_colleagues": "Amici o colleghi?<br/> <a ng-click=\"onClickAlumniNetwork()\">Cerca nella nostra rete di ex studenti</a>.",
    "welcome": "Benvenuto in {{brandName}} !",
    "need_more_info": "Hai bisogno di maggiori informazioni?<br/> <a ng-click=\"onClickNeedMoreInfo()\">Visualizza il sito web {{brandName}}</a> .",
    "try_our_lessons": "Prova le nostre lezioni<br /> <a ng-click=\"onClickOpenCourses()\">Esempio di corsi aperti</a>"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.admissions_modals = window.locales.zh.admissions_modals || {};window.locales.zh.admissions_modals.welcome_to_dashboard = window.locales.zh.admissions_modals.welcome_to_dashboard || {};window.locales.zh.admissions_modals.welcome_to_dashboard = {...window.locales.zh.admissions_modals.welcome_to_dashboard, ...{
    "try_any_free_lessons": "准备好学习了吗？<br/><a ng-click=\"closeModal()\">试试我们的公开课程</a>。",
    "complete_your_application": "准备好申请了吗？<br/><a ng-click=\"completeApplication()\">完成免费申请</a>。",
    "admissions_question": "招生问题？<br/><a ng-click=\"onClickAdmissionsQuestion()\">在这里得到答案</a>。",
    "friends_or_colleagues": "朋友还是同事？<br/><a ng-click=\"onClickAlumniNetwork()\">搜索我们的校友网络</a>。",
    "welcome": "欢迎来到{{brandName}} ！",
    "need_more_info": "需要更多信息吗？<br/><a ng-click=\"onClickNeedMoreInfo()\">查看{{brandName}}网站</a>。",
    "try_our_lessons": "尝试我们的课程<br /><a ng-click=\"onClickOpenCourses()\">开放课程样本</a>"
}
};