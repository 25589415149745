window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.authentication = window.locales.am.authentication || {};window.locales.am.authentication.institution_register = window.locales.am.authentication.institution_register || {};window.locales.am.authentication.institution_register = {...window.locales.am.authentication.institution_register, ...{
    "already_have_an_account": "ቀድሞውኑ መለያ አለዎት? <a ng-click='gotoSignIn(false)'>ይግቡ</a>",           
    "next": "ቀጣይ",           
    "sign_up": "ለ <strong>{{brandName}}</strong> ይመዝገቡ",           
    "free_demo": "{{brandName}} ነፃ ማሳያ",           
    "subtitle_math101a": "የሂሳብ መሰረቶች፡- Math 101A<br>ፕሮፌሶር ዳንኤል ሚንትዝ<br>ማክሰኞ ከሰአት 1 ሰአት<br>በልግ 2016",           
    "subtitle_math101b": "የሂሳብ መሰረቶች፡- Math 101B<br>ፕሮፌሶር ዳንኤል ሚንትዝ<br>ረቡዕ ከሰአት 7 ሰአት<br>በልግ 2016",           
    "subtitle_iebusiness": "ለ IE አለም አቀፍ MBA ተማሪዎች",           
    "subtitle_research_study17": "እባክዎ ለጥናቱ ለመመዝገብ የተጠቀሙበትን የኢሜይል አድራሻ በመጠቀም አዲስ መለያን ይፍጠሩ። ማንኛውንም የይለፍ ቃል መይምረጡ ይችላሉ።",           
    "subtitle_georgetownmim": "ጆርጅታውን በማኔጅመንት ማስተርስ ፕሮግራም"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.authentication = window.locales.ar.authentication || {};window.locales.ar.authentication.institution_register = window.locales.ar.authentication.institution_register || {};window.locales.ar.authentication.institution_register = {...window.locales.ar.authentication.institution_register, ...{
    "already_have_an_account": "هل لديك حساب بالفعل؟ <a ng-click='gotoSignIn(false)'>تسجيل الدخول</a>",           
    "next": "التالى",           
    "subtitle_math101a": "أسس الرياضيات: الرياضيات 101A<br>البروفيسور دانيال مينتز<br>الثلاثاء الساعة الواحدة ظهراً<br>خريف 2016",           
    "subtitle_math101b": "أسس الرياضيات: الرياضيات 101B<br>البروفيسور دانيال مينتز<br>الأربعاء الساعة السابعة مساءً<br>خريف 2016",           
    "subtitle_iebusiness": "للطلاب IE الدولية ماجستير في إدارة الأعمال",
    "subtitle_research_study17": "الرجاء إنشاء حساب جديد باستخدام عنوان البريد الإلكتروني الذي استخدمته للاشتراك في الدراسة. يمكنك اختيار أي كلمة.",
    "subtitle_georgetownmim": "ماجستير في برنامج جورج تاون للإدارة",
    "sign_up": "الاشتراك في <strong>{{brandName}}</strong>",
    "free_demo": "{{brandName}} عرض توضيحي مجاني"
}
};window.locales.en = window.locales.en || {};window.locales.en.authentication = window.locales.en.authentication || {};window.locales.en.authentication.institution_register = window.locales.en.authentication.institution_register || {};window.locales.en.authentication.institution_register = {...window.locales.en.authentication.institution_register, ...{
    "already_have_an_account": "Already have an account? <a ng-click='gotoSignIn(false)'>Login</a>",
    "next": "Next",
    "sign_up": "Sign up for <strong>{{brandName}}</strong>",
    "free_demo": "{{brandName}} Free Demo",
    "subtitle_math101a": "Foundations of Math: Math 101A<br>Professor Daniel Mintz<br>Tuesday 1pm<br>Fall 2016",
    "subtitle_math101b": "Foundations of Math: Math 101B<br>Professor Daniel Mintz<br>Wednesday 7pm<br>Fall 2016",
    "subtitle_iebusiness": "For IE International MBA students",
    "subtitle_research_study17": "Please create a new account using the email address you used to sign up for the study. You may choose any password.",
    "subtitle_georgetownmim": "Georgetown Master's in Management Program"
}
};window.locales.es = window.locales.es || {};window.locales.es.authentication = window.locales.es.authentication || {};window.locales.es.authentication.institution_register = window.locales.es.authentication.institution_register || {};window.locales.es.authentication.institution_register = {...window.locales.es.authentication.institution_register, ...{
    "already_have_an_account": "¿Ya tienes una cuenta? <a ng-click='gotoSignIn(false)'>Iniciar sesión</a>",           
    "next": "Siguiente",           
    "subtitle_math101a": "Fundamentos de matemáticas: matemáticas 101A <br>Profesor Daniel Mintz<br> Martes a las 13:00 <br> Otoño de 2016",           
    "subtitle_math101b": "Fundamentos de matemáticas: matemáticas 101B <br> Profesor Daniel Mintz <br> Miércoles a las 19:00 <br> Otoño de 2016",           
    "subtitle_iebusiness": "Para los estudiantes de MBA de IE Internacional",
    "subtitle_research_study17": "Por favor, cree una nueva cuenta utilizando la dirección de correo electrónico que utilizó para inscribirse en el estudio. Usted puede elegir cualquier contraseña.",
    "subtitle_georgetownmim": "Programa de Master en Gestión de Georgetown",
    "sign_up": "Regístrese para <strong>{{brandName}}</strong>",
    "free_demo": "{{brandName}} Demo gratis"
}
};window.locales.it = window.locales.it || {};window.locales.it.authentication = window.locales.it.authentication || {};window.locales.it.authentication.institution_register = window.locales.it.authentication.institution_register || {};window.locales.it.authentication.institution_register = {...window.locales.it.authentication.institution_register, ...{
    "already_have_an_account": "Hai già un account? <a ng-click='gotoSignIn(false)'>Accedi</a>",           
    "next": "Avanti",           
    "subtitle_math101a": "Fondamenti di Matematica: Math 101A<br>Professor Daniel Mintz<br>Martedì 13:00<br>Autunno 2016",           
    "subtitle_math101b": "Fondamenti di Matematica: Math 101B<br>Professor Daniel Mintz<br>Mercoledì 19:00<br>Autunno 2016",           
    "subtitle_iebusiness": "Per studenti MBA IE International",           
    "subtitle_research_study17": "Per favore, crea un nuovo account usando l'indirizzo email che hai usato per iscriverti allo studio. Puoi scegliere la password liberamente.",           
    "subtitle_georgetownmim": "Il programma di gestione del master di Georgetown",
    "sign_up": "Iscriviti a <strong>{{brandName}}</strong>",
    "free_demo": "{{brandName}} Demo gratuita"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.authentication = window.locales.zh.authentication || {};window.locales.zh.authentication.institution_register = window.locales.zh.authentication.institution_register || {};window.locales.zh.authentication.institution_register = {...window.locales.zh.authentication.institution_register, ...{
    "already_have_an_account": "已有帐户？<a ng-click='gotoSignIn(false)'>登录</a>",           
    "next": "下一步",           
    "subtitle_math101a": "数学基础：数学101A<br>丹尼尔·明茨教授<br>星期二下午1点<br>2016年秋季",           
    "subtitle_math101b": "数学基础：数学101B<br>丹尼尔·明茨教授<br>星期三下午7点<br>2016年秋季",           
    "subtitle_iebusiness": "IE大学国际MBA学员",           
    "subtitle_research_study17": "请使用你注册学习时所使用的电子邮箱创建一个新账号。密码可任意选择。",           
    "subtitle_georgetownmim": "乔治城大学管理程序硕士",           
    "sign_up": "注册 <strong>{{brandName}}</strong>",           
    "free_demo": "{{brandName}} 免费演示"           
}
};