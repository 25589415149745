import { InstitutionId } from 'Institutions';
import { SMARTLY_DOMAIN, QUANTIC_DOMAIN, VALAR_DOMAIN } from 'PedagoDomainConstants';

import paperCertificateSmartly from 'images/paper_certificate_smartly.png';
import paperCertificateQuantic from 'images/paper_certificate_quantic.png';
import paperCertificateValar from 'images/paper_certificate_valar.png';

import learnerLayoutLogo from 'vectors/header_logo.svg';
import learnerLayoutLogoQuantic from 'vectors/header_logo_quantic.svg';

import wordmarkQuantic from 'vectors/wordmark_white_quantic.svg';
import wordmarkValar from 'images/wordmark_white_valar.png';
import wordmarkWhite from 'vectors/wordmark_white.svg';

import miyaMiyaLogoWhite from 'images/miyamiya/miyamiya-logo-white.png';

import lessonLayout from 'vectors/header_logo_white.svg';
import lessonLayoutQuantic from 'vectors/header_logo_white_quantic.svg';

import avatarDefault from 'images/avatar_default.png';
import avatarDefaultQuantic from 'images/avatar_default_quantic.png';
import avatarAltQuantic from 'images/avatar_alt_quantic.png';
import avatarDefaultValar from 'images/avatar_default_valar.png';
import avatarDefaultMiyaMiya from 'images/onboarding/Message-Sender_miyamiya.png';

import logoRed from 'vectors/logo-red.svg';
import logoRedQuantic from 'vectors/logo-red_quantic.svg';
import logoBlueValar from 'images/logo-blue_valar.png';
import logoMiyaMiya from 'images/miyamiya/miyamiya-logo.png';
import logoColorQuantic from 'vectors/logos/quantic-logo-color.svg';
import logoOnlyQuantic from 'vectors/logos/quantic-logo-only.svg';
import logoOnlyValar from 'vectors/logos/valar-logo-only.svg';
import logoOnlySmartly from 'vectors/logos/smartly-logo-only.svg';
import logoWordmarkLightValar from 'vectors/logos/valar-logo-wordmark-light-bg.svg';

import logoWhite from 'vectors/logo-white.svg';
import logoWhiteQuantic from 'vectors/logo-white_quantic.svg';
import logoWhiteValar from 'vectors/logo-white_valar.svg';

import shield from 'images/shield.png';
import shieldQuantic from 'images/shield_quantic.png';

import badge from 'images/badge.png';
import badgeQuantic from 'images/badge_quantic.png';
import badgeValar from 'images/badge_valar.png';

import emptyCertDefault from 'images/empty_cert_default.png';
import emptyCertDefaultQuantic from 'images/empty_cert_default_quantic.png';

import menuHomeWhite from 'images/miyamiya/menu_home_white.png';
import menuLogoWhite from 'images/menu_logo_white.png';
import menuLogoWhiteQuantic from 'images/menu_logo_white_quantic.png';
import menuLogoWhiteValar from 'images/menu_logo_white_valar.png';

import hexDarkBlue from 'images/onboarding/Hex-DarkBlue@2x.png';
import hexDarkBlueQuantic from 'images/onboarding/Hex-DarkBlue@2x_quantic.png';

import logoBig from 'images/onboarding/Logo-Big@2x.png';
import logoBigQuantic from 'images/onboarding/Logo-Big@2x_quantic.png';

import programGuideBadgeQuantic from 'vectors/program-guide-badge-gray_quantic.svg';
import programGuideBadgeValar from 'vectors/program-guide-badge-gray_valar.svg';

import quanticCohortScheduleCover from 'raw/cohort_schedules/Cover_quantic.png';
import quanticCohortSchedulePageA from 'raw/cohort_schedules/PageA_quantic.png';
import quanticCohortSchedulePageB from 'raw/cohort_schedules/PageB_quantic.png';

import valarCohortScheduleCover from 'raw/cohort_schedules/Cover_valar.png';
import valarCohortSchedulePageA from 'raw/cohort_schedules/PageA_valar.png';
import valarCohortSchedulePageB from 'raw/cohort_schedules/PageB_valar.png';

import digitalFrontFull from 'raw/transcripts/DigitalFrontFull.png';
import digitalFrontFullQuantic from 'raw/transcripts/DigitalFrontFull_quantic.png';
import digitalFrontFullUnofficialQuantic from 'raw/transcripts/DigitalFrontFullUnofficial_quantic.png';
import digitalFrontFullValar from 'raw/transcripts/DigitalFrontFull_valar.png';
import digitalFrontFullUnofficialValar from 'raw/transcripts/DigitalFrontFullUnofficial_valar.png';

import digitalBack from 'raw/transcripts/DigitalBack.png';
import digitalBackQuanticValar from 'raw/transcripts/DigitalBack_quantic_valar.png';
import digitalBackUnofficialQuanticValar from 'raw/transcripts/DigitalBackUnofficial_quantic_valar.png';

import digitalFrontPartial from 'raw/transcripts/DigitalFrontPartial.png';
import digitalFrontPartialQuantic from 'raw/transcripts/DigitalFrontPartial_quantic.png';
import digitalFrontPartialUnofficialQuantic from 'raw/transcripts/DigitalFrontPartialUnofficial_quantic.png';
import digitalFrontPartialValar from 'raw/transcripts/DigitalFrontPartial_valar.png';
import digitalFrontPartialUnofficialValar from 'raw/transcripts/DigitalFrontPartialUnofficial_valar.png';

import printableFrontFull from 'raw/transcripts/PrintableFrontFull.png';
import printableFrontFullQuantic from 'raw/transcripts/PrintableFrontFull_quantic.png';
import printableFrontFullValar from 'raw/transcripts/PrintableFrontFull_valar.png';

import printableFrontPartial from 'raw/transcripts/PrintableFrontPartial.png';
import printableFrontPartialQuantic from 'raw/transcripts/PrintableFrontPartial_quantic.png';
import printableFrontPartialValar from 'raw/transcripts/PrintableFrontPartial_valar.png';

import printableBack from 'raw/transcripts/PrintableBack.png';
import printableBackQuanticValar from 'raw/transcripts/PrintableBack_quantic_valar.png';

import { ProgramAchievementGraphicDesignKey } from 'ShareableGraphics/ShareableGraphics.types';
import { GraduatedGraphicQuantic } from 'ShareableGraphics/components/ProgramAchievementGraphic/variants/GraduatedGraphicQuantic';
import { GraduatedGraphicValar } from 'ShareableGraphics/components/ProgramAchievementGraphic/variants/GraduatedGraphicValar';
import { AcceptedGraphicQuantic } from 'ShareableGraphics/components/ProgramAchievementGraphic/variants/AcceptedGraphicQuantic';
import { AcceptedGraphicValar } from 'ShareableGraphics/components/ProgramAchievementGraphic/variants/AcceptedGraphicValar';

import Oreo from 'Oreo';
import { type Config as FrontRoyalConfig } from 'FrontRoyalConfig';
import { Brand, type BrandConfig, type BrandOrInstitutionId } from './AppBranding.types';
import fetchBrandNameConfig from './fetchBrandNameConfig';

const miyaMiyaBrandNameConfig = fetchBrandNameConfig(Brand.miya_miya);
const quanticBrandNameConfig = fetchBrandNameConfig(Brand.quantic);
const smartlyBrandNameConfig = fetchBrandNameConfig(Brand.smartly);
const valarBrandNameConfig = fetchBrandNameConfig(Brand.valar);

const baseConfig = {
    appHeaderHomeTitleNameProperty: null,
    brandStyleClass: Brand.smartly,
    acceptedApplicationBadge: null,
    acceptedApplicationLogo: null,
    admittedStudentInfo: (_config: FrontRoyalConfig) => ({
        registrationUrl: null,
        recordingUrl: null,
        webinarDate: null,
    }),
    appHeaderLearnerLayoutLogo: null,
    appHeaderLessonLayoutLogo: null,
    appMenuProgramLogo: logoOnlySmartly,
    degreeProgramDurationMonths: 0,
    emptyCertDefault,
    fundingContactUrl: null,
    learnMoreAboutProgramsUrl: null,
    learnMoreAboutMbaUrl: null,
    learnMoreAboutTuitionUrl: null,
    learnMoreAboutEmbaUrl: null,
    learnMoreAboutMsbaUrl: null,
    learnMoreAboutMsseUrl: null,
    onboardingHeaderLogoClass: null,
    onboardingHybridStartHex: null,
    onboardingHybridStartLogo: null,
    ourDegreeProgramsSidebarBoxButtons: [],
    programPreferencesLogo: null,
    programPreferencesLogoImgStyles: null,
    programPreferencesSubHeadingLocaleKey: null,
    programWelcomePackageBadge: null,
    scholarshipLabelsMap: { merit: '', impact: '', employer_partner: '', recruitment: '', need: '', alumni: '' },
    showScholarshipEligibilityNotice: false,
    sessionsNavigationClosedLogo: null,
    sessionsNavigationOpenLogo: null,
    shortAnswerWhyPursuingBrandName: null,
    stripeImage: null,
    supportsFeedbackSidebar: true,
    supportsMobileAppRateHelper: true,
    supportsSelectProgram: false,
    downloadableCohortScheduleConfig: {},
    transcriptImages: {},
    urlPrefix: '',
    themeColor: Oreo.COLOR_V3_CORAL,
    programAchievementGraphics: null,
    tutorBotFaqUrl: null,
    followUsUrl: null,
    round62RefundPolicyUrl: null,
    avatarAlt: null,
    academicAdvisingUrl: null,
};

const QuanticConfig: BrandConfig = {
    ...baseConfig,
    ...quanticBrandNameConfig,
    admittedStudentInfo: (config: FrontRoyalConfig) => ({
        registrationUrl: config.admitted_registration_url || null,
        recordingUrl: config.admitted_recording_url || null,
        webinarDate: config.admitted_webinar_date ? new Date(config.admitted_webinar_date) : null,
    }),
    appHeaderHomeTitleNameProperty: 'brandNameLong',
    branding: Brand.quantic,
    brandStyleClass: Brand.quantic,
    acceptedApplicationBadge: badgeQuantic,
    acceptedApplicationLogo: logoWhiteQuantic,
    appHeaderStyleClasses: [Brand.quantic],
    appHeaderLearnerLayoutLogo: learnerLayoutLogoQuantic,
    appHeaderLessonLayoutLogo: lessonLayoutQuantic,
    appHeaderWordMarkSrc: wordmarkQuantic,
    appMenuMobileLogo: menuLogoWhiteQuantic,
    appMenuProgramLogo: logoOnlyQuantic,
    avatarDefault: avatarDefaultQuantic,
    completeRegistrationLogo: logoRedQuantic,
    dashboardFooterLogo: logoRedQuantic,
    degreeProgramDurationMonths: 15,
    emailAddressForUsername: username => `${username}@${QUANTIC_DOMAIN}`,
    emptyCertDefault: emptyCertDefaultQuantic,
    fundingContactUrl: `https://${QUANTIC_DOMAIN}/contact`,
    defaultNotchBackgroundClass: 'bg-coral',
    institutionId: InstitutionId.quantic,
    learnMoreAboutProgramsUrl: `https://${QUANTIC_DOMAIN}/programs`,
    learnMoreAboutMbaUrl: `https://${QUANTIC_DOMAIN}/mba`,
    learnMoreAboutEmbaUrl: `https://${QUANTIC_DOMAIN}/executive-mba`,
    learnMoreAboutMsbaUrl: `https://${QUANTIC_DOMAIN}/ms-business-analytics`,
    learnMoreAboutMsseUrl: `https://${QUANTIC_DOMAIN}/ms-software-engineering`,
    learnMoreAboutTuitionUrl: `https://${QUANTIC_DOMAIN}/tuition/employer-funding`,
    lessonFinishScreenShield: shieldQuantic,
    loggedOutPageLogo: logoRedQuantic,
    onboardingHeaderLogoClass: 'img_logo-quantic',
    onboardingHybridStartHex: hexDarkBlueQuantic,
    onboardingHybridStartLogo: logoBigQuantic,
    ourDegreeProgramsSidebarBoxButtons: ['button_the_mba', 'button_the_executive_mba', 'button_msba', 'button_msse'],
    paperCertificate: paperCertificateQuantic,
    programPreferencesLogo: logoColorQuantic,
    programPreferencesLogoImgStyles: { 'max-width': '150px' },
    programPreferencesSubHeadingLocaleKey: 'program_preferences_desc',
    programWelcomePackageBadge: programGuideBadgeQuantic,
    scholarshipLabelsMap: {
        merit: 'strada',
        impact: 'impact_quantic',
        employer_partner: 'employer_partner',
        recruitment: 'recruitment',
        need: 'powell',
        alumni: 'alumni',
    },
    sessionsNavigationClosedLogo: logoWhiteQuantic,
    sessionsNavigationOpenLogo: logoRedQuantic,
    shortAnswerWhyPursuingBrandName: quanticBrandNameConfig.brandNameLong,
    stripeImage: 'https://uploads.smart.ly/downloads/stripe/quantic.edu/favicon-192x192.png',
    studentProfileListCardLogo: logoRedQuantic,
    supportsSelectProgram: true,
    downloadableCohortScheduleConfig: {
        cover: quanticCohortScheduleCover,
        pageA: quanticCohortSchedulePageA,
        pageB: quanticCohortSchedulePageB,
        coverPageTitleX: 62,
        coverPageTitleY: 119,
        coverPageWelcomeMessageY: 581,
    },
    transcriptImages: {
        digitalFrontFull: digitalFrontFullQuantic,
        digitalFrontFullUnofficial: digitalFrontFullUnofficialQuantic,
        digitalFrontPartial: digitalFrontPartialQuantic,
        digitalFrontPartialUnofficial: digitalFrontPartialUnofficialQuantic,
        digitalBack: digitalBackQuanticValar,
        digitalBackUnofficial: digitalBackUnofficialQuanticValar,
        printableFrontFull: printableFrontFullQuantic,
        printableFrontPartial: printableFrontPartialQuantic,
        printableBack: printableBackQuanticValar,
    },
    programAchievementGraphics: {
        accepted: {
            availableDesigns: [
                ProgramAchievementGraphicDesignKey.quanticDarkBlue,
                ProgramAchievementGraphicDesignKey.quanticCoral,
                ProgramAchievementGraphicDesignKey.quanticPurple,
            ],
            institutionNameLines: [{ text: 'Quantic School of Business and Technology', fontSize: '22' }],
            component: AcceptedGraphicQuantic,
        },
        graduated: {
            availableDesigns: [
                ProgramAchievementGraphicDesignKey.quanticDarkBlue,
                ProgramAchievementGraphicDesignKey.quanticCoral,
                ProgramAchievementGraphicDesignKey.quanticPurple,
            ],
            institutionName: quanticBrandNameConfig.brandNameLong,
            institutionNameLines: [{ text: 'Quantic School of Business and Technology', fontSize: '22' }],
            component: GraduatedGraphicQuantic,
        },
    },
    tutorBotFaqUrl: `https://support.quantic.edu/article/1082-tutorbot-ai-chatbot`,
    followUsUrl: `https://linkedin.com/school/quanticschool/`,
    round62RefundPolicyUrl:
        'https://support.quantic.edu/article/1274-tuition-refund-policy-class-of-january-2026-and-beyond',
    avatarAlt: avatarAltQuantic,
    academicAdvisingUrl: 'https://support.quantic.edu/article/860-academic-advising',
};

const ValarConfig: BrandConfig = {
    ...baseConfig,
    ...valarBrandNameConfig,
    admittedStudentInfo: (config: FrontRoyalConfig) => ({
        registrationUrl: config.admitted_registration_url_valar || null,
        recordingUrl: config.admitted_recording_url_valar || null,
        webinarDate: config.admitted_webinar_date ? new Date(config.admitted_webinar_date) : null,
    }),
    appHeaderHomeTitleNameProperty: 'brandNameStandard',
    branding: Brand.valar,
    brandStyleClass: Brand.valar,
    acceptedApplicationBadge: badgeValar,
    acceptedApplicationLogo: logoWhiteValar,
    appHeaderStyleClasses: [Brand.valar, 'nav-pills-blue'],
    appHeaderLessonLayoutLogo: null, // not needed since it's only used in the lesson editor and demo mode in the lesson player
    appHeaderWordMarkSrc: wordmarkValar,
    appMenuMobileLogo: menuLogoWhiteValar,
    appMenuProgramLogo: logoOnlyValar,
    avatarDefault: avatarDefaultValar,
    completeRegistrationLogo: logoBlueValar,
    dashboardFooterLogo: logoBlueValar,
    degreeProgramDurationMonths: 12,
    defaultNotchBackgroundClass: 'bg-blue',
    emailAddressForUsername: username => `${username}@${VALAR_DOMAIN}`,
    fundingContactUrl: `mailto:funding@${VALAR_DOMAIN}`,
    institutionId: InstitutionId.valar,
    learnMoreAboutProgramsUrl: `https://${VALAR_DOMAIN}`,
    learnMoreAboutMbaUrl: `https://${VALAR_DOMAIN}/mba`,
    learnMoreAboutEmbaUrl: `https://${VALAR_DOMAIN}/executive-mba`,
    learnMoreAboutTuitionUrl: `https://${VALAR_DOMAIN}/tuition`,
    lessonFinishScreenShield: null, // not needed since it's only used in demo mode in the lesson player
    loggedOutPageLogo: logoBlueValar,
    onboardingHeaderLogoClass: null, // not needed since Valar doesn't have its own dedicated mobile app yet
    onboardingHybridStartHex: null, // not needed since Valar doesn't have its own dedicated mobile app yet
    onboardingHybridStartLogo: null, // not needed since Valar doesn't have its own dedicated mobile app yet
    ourDegreeProgramsSidebarBoxButtons: ['button_the_mba', 'button_the_executive_mba'],
    paperCertificate: paperCertificateValar,
    programPreferencesLogo: logoWordmarkLightValar,
    programPreferencesLogoImgStyles: { 'max-width': '225px' },
    programPreferencesSubHeadingLocaleKey: 'program_preferences_desc_valar',
    programWelcomePackageBadge: programGuideBadgeValar,
    scholarshipLabelsMap: {
        merit: 'zenith',
        impact: 'impact_valar',
        employer_partner: 'employer_partner',
        recruitment: 'recruitment',
        need: 'hyperion',
        alumni: 'alumni',
    },
    sessionsNavigationClosedLogo: null, // not needed since Valar uses a different sessions navigation UI
    sessionsNavigationOpenLogo: null, // not needed since Valar uses a different sessions navigation UI
    shortAnswerWhyPursuingBrandName: valarBrandNameConfig.brandNameStandard,
    stripeImage: 'https://uploads.smart.ly/downloads/stripe/quantic.edu/valar-favicon-192x192.png',
    studentProfileListCardLogo: logoBlueValar,
    downloadableCohortScheduleConfig: {
        cover: valarCohortScheduleCover,
        pageA: valarCohortSchedulePageA,
        pageB: valarCohortSchedulePageB,
        coverPageTitleX: 97,
        coverPageTitleY: 292,
        coverPageWelcomeMessageY: 557,
    },
    transcriptImages: {
        digitalFrontFull: digitalFrontFullValar,
        digitalFrontFullUnofficial: digitalFrontFullUnofficialValar,
        digitalFrontPartial: digitalFrontPartialValar,
        digitalFrontPartialUnofficial: digitalFrontPartialUnofficialValar,
        digitalBack: digitalBackQuanticValar,
        digitalBackUnofficial: digitalBackUnofficialQuanticValar,
        printableFrontFull: printableFrontFullValar,
        printableFrontPartial: printableFrontPartialValar,
        printableBack: printableBackQuanticValar,
    },
    urlPrefix: 'valar/',
    themeColor: Oreo.COLOR_V3_BLUE,
    programAchievementGraphics: {
        accepted: {
            availableDesigns: [
                ProgramAchievementGraphicDesignKey.valarBlue,
                ProgramAchievementGraphicDesignKey.valarPurple,
                ProgramAchievementGraphicDesignKey.valarGreen,
            ],
            component: AcceptedGraphicValar,
            institutionNameLines: [{ text: 'Valar Institute', fontSize: '22' }],
            institutionName: valarBrandNameConfig.brandNameStandard,
        },
        graduated: {
            availableDesigns: [
                ProgramAchievementGraphicDesignKey.valarBlue,
                ProgramAchievementGraphicDesignKey.valarPurple,
                ProgramAchievementGraphicDesignKey.valarGreen,
            ],
            institutionNameLines: [{ text: 'Valar Institute', fontSize: '22' }],
            institutionName: valarBrandNameConfig.brandNameStandard,
            component: GraduatedGraphicValar,
        },
    },
    tutorBotFaqUrl: `https://valar-support.quantic.edu/article/1083-tutorbot-ai-chatbot`,
    followUsUrl: `https://linkedin.com/school/valar-institute/`,
    round62RefundPolicyUrl:
        'https://valar-support.quantic.edu/article/1275-tuition-refund-policy-class-of-september-2025-and-beyond',
    academicAdvisingUrl: 'https://valar-support.quantic.edu/article/999-academic-advising',
};

const SmartlyConfig: BrandConfig = {
    ...baseConfig,
    ...smartlyBrandNameConfig,
    appHeaderHomeTitleNameProperty: 'brandNameLong',
    branding: Brand.smartly,
    acceptedApplicationBadge: badge,
    appHeaderStyleClasses: [Brand.smartly],
    appHeaderLearnerLayoutLogo: learnerLayoutLogo,
    appHeaderLessonLayoutLogo: lessonLayout,
    appHeaderWordMarkSrc: wordmarkWhite,
    appMenuMobileLogo: menuLogoWhite,
    avatarDefault,
    completeRegistrationLogo: logoRed,
    dashboardFooterLogo: logoRed,
    emailAddressForUsername: username => `${username}@${SMARTLY_DOMAIN}`,
    defaultNotchBackgroundClass: 'bg-coral',
    institutionId: InstitutionId.smartly,
    learnMoreAboutProgramsUrl: `https://${QUANTIC_DOMAIN}/programs`,
    lessonFinishScreenShield: shield,
    loggedOutPageLogo: logoRed,
    onboardingHeaderLogoClass: 'img_logo-smartly',
    onboardingHybridStartHex: hexDarkBlue,
    onboardingHybridStartLogo: logoBig,
    paperCertificate: paperCertificateSmartly,
    sessionsNavigationClosedLogo: logoWhite,
    sessionsNavigationOpenLogo: logoRed,
    shortAnswerWhyPursuingBrandName: smartlyBrandNameConfig.brandNameLong,
    studentProfileListCardLogo: logoRed,
    transcriptImages: {
        digitalFrontFull,
        digitalFrontPartial,
        digitalBack,
        printableFrontFull,
        printableFrontPartial,
        printableBack,
    },
    tutorBotFaqUrl: `https://support.quantic.edu/article/1082-tutorbot-ai-chatbot`,
};

const MiyaMiyaConfig: BrandConfig = {
    ...baseConfig,
    ...miyaMiyaBrandNameConfig,
    appHeaderHomeTitleNameProperty: 'brandNameLong',
    branding: Brand.miya_miya,
    acceptedApplicationBadge: badge,
    appHeaderStyleClasses: [Brand.miya_miya],
    appHeaderLessonLayoutLogo: lessonLayout,
    appHeaderWordMarkSrc: miyaMiyaLogoWhite,
    appMenuMobileLogo: menuHomeWhite,
    avatarDefault: avatarDefaultMiyaMiya,
    completeRegistrationLogo: logoMiyaMiya,
    dashboardFooterLogo: logoMiyaMiya,
    emailAddressForUsername: username => `${username}@${SMARTLY_DOMAIN}`,
    defaultNotchBackgroundClass: 'bg-coral',
    institutionId: InstitutionId.miya_miya,
    lessonFinishScreenShield: shield,
    loggedOutPageLogo: logoRed, // FIXME: Maybe this should be logoMiyaMiya?
    onboardingHeaderLogoClass: 'img_logo-miyamiya',
    onboardingHybridStartHex: hexDarkBlue,
    onboardingHybridStartLogo: logoBig,
    paperCertificate: paperCertificateSmartly,
    sessionsNavigationClosedLogo: logoWhite,
    sessionsNavigationOpenLogo: logoRed,
    supportsFeedbackSidebar: false,
    supportsMobileAppRateHelper: false,
    studentProfileListCardLogo: logoMiyaMiya, // I don't think it's possbile for this to be used, but I'm setting it anyway.
    tutorBotFaqUrl: `https://support.quantic.edu/article/1082-tutorbot-ai-chatbot`,
    urlPrefix: 'miyamiya/',
};

// FIXME: We should refactor this so that you can only access brand configs by institution id.
// See https://trello.com/c/MCBBecGk.
const AppBrandConfigs: Record<BrandOrInstitutionId, BrandConfig> = {
    quantic: QuanticConfig,
    [InstitutionId.quantic]: QuanticConfig,

    valar: ValarConfig,
    [InstitutionId.valar]: ValarConfig,

    smartly: SmartlyConfig,
    [InstitutionId.smartly]: SmartlyConfig,

    miyamiya: MiyaMiyaConfig,
    [InstitutionId.miya_miya]: MiyaMiyaConfig,
};

export default AppBrandConfigs;
