import ClientStorage from 'ClientStorage';
import { generateGuid } from 'guid';

const Auid = {
    // I am writing this years after this method was first written, but it seems unnecessary
    // to pass a user in here. We always make sure the auid is set in client storage whenever
    // there is a current user, so it seems like this method should return the same thing in all
    // cases regardless of whether a currentUser is passed in or not.
    get(currentUser) {
        let storedAuid = currentUser?.id || ClientStorage.getItem('auid');

        // we've seen this somehow set to 'undefined' in ClientStorage (perhaps due to some legacy code),
        // so we're insulating from error if we've detected this situation.
        if (storedAuid === 'undefined' || storedAuid === 'null') {
            storedAuid = undefined;
            this.remove();
        }
        return storedAuid;
    },

    set(auid) {
        if (!auid) {
            this.remove();
            return;
        }

        ClientStorage.setItem('auid', auid);
    },

    ensure(currentUser) {
        if (!this.get(currentUser)) {
            const auid = generateGuid();
            this.set(auid);
        }

        return this.get();
    },

    reset() {
        this.remove();
        return this.ensure();
    },

    remove() {
        ClientStorage.removeItem('auid');
    },
};

export default Auid;
