window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.authentication = window.locales.am.authentication || {};window.locales.am.authentication.forgot_password = window.locales.am.authentication.forgot_password || {};window.locales.am.authentication.forgot_password = {...window.locales.am.authentication.forgot_password, ...{
    "check_your_email": "ኢሜልዎን ያፈትሹ",           
    "enter_your_email": "የኢሜይል አድራሻዎን ያስገቡና የይለፍ ቃልዎን እንደገና ለማስጀመር መመሪያዎችን እንልክልዎታለን።",           
    "forgot_your_password": "የይለፍ ቃልዎን ረሱት?",           
    "reset_my_password": "የይለፍ ቃሌን ቀይር",           
    "return_to_login": "ወደ <a ng-click=\"gotoSignIn()\" >ይግቡ</a> ተመለስ",           
    "recover_letter": "{{brandName}} የመልሶ ማግኛ ደብዳቤ",           
    "we_sent_you_an_email": "የይለፍ ቃልዎን ለመቀየር መመሪያዎችን የያዘ ኢሜይል ልከንልዎታል።",           
    "your_email": "የእርስዎ ኢሜይል",           
    "unable_to_process_your_request": "ጥያቄዎን ማስተናገድ አልተቻለም። እባክዎ የኢሜይል አድራሻዎ ትክክል መሆኑን ያረጋግጡ።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.authentication = window.locales.ar.authentication || {};window.locales.ar.authentication.forgot_password = window.locales.ar.authentication.forgot_password || {};window.locales.ar.authentication.forgot_password = {...window.locales.ar.authentication.forgot_password, ...{
    "check_your_email": "تحقق من بريدك الإلكتروني",           
    "enter_your_email": "أدخل عنوان بريدك الإلكتروني وسوف نرسل إليك تعليمات لإعادة تعيين كلمة المرور الخاصة بك.",           
    "forgot_your_password": "هل نسيت كلمة المرور الخاصة بك؟",           
    "reset_my_password": "إعادة تعيين كلمة المرور الخاصة بي",           
    "return_to_login": "الرجوع إلى <a ng-click=\"gotoSignIn()\" >تسجيل الدخول</a>",           
    "we_sent_you_an_email": "لقد قمنا بإرسال رسالة بريد إلكتروني تحتوي على تعليمات إعادة تعيين كلمة المرور الخاصة بك.",           
    "your_email": "بريدك الإلكتروني",           
    "unable_to_process_your_request": "النظام غير قادر على معالجة طلبك. يرجى التأكد من أن عنوان بريدك الإلكتروني صحيح.",           
    "recover_letter": "{{brandName}} استرداد الرسالة"
}
};window.locales.en = window.locales.en || {};window.locales.en.authentication = window.locales.en.authentication || {};window.locales.en.authentication.forgot_password = window.locales.en.authentication.forgot_password || {};window.locales.en.authentication.forgot_password = {...window.locales.en.authentication.forgot_password, ...{
    "check_your_email": "Check your email",
    "enter_your_email": "Enter your email address and we'll send you instructions to reset your password.",
    "forgot_your_password": "Forgot your password?",
    "reset_my_password": "Reset my password",
    "return_to_login": "Return to <a ng-click=\"gotoSignIn()\" >Login</a>",
    "recover_letter": "{{brandName}} Recover Letter",
    "we_sent_you_an_email": "We sent you an email with instructions for resetting your password.",
    "your_email": "Your email",
    "unable_to_process_your_request": "Unable to process your request. Please make sure your email address is correct."
}
};window.locales.es = window.locales.es || {};window.locales.es.authentication = window.locales.es.authentication || {};window.locales.es.authentication.forgot_password = window.locales.es.authentication.forgot_password || {};window.locales.es.authentication.forgot_password = {...window.locales.es.authentication.forgot_password, ...{
    "check_your_email": "Consulta tu correo electrónico",           
    "enter_your_email": "Introduce tu dirección de correo electrónico y te enviaremos instrucciones para restablecer tu contraseña.",           
    "forgot_your_password": "¿Olvidaste tu contraseña?",           
    "reset_my_password": "Restablecer mi contraseña",           
    "return_to_login": "Volver a <a ng-click=\"gotoSignIn()\" >Inicio de sesión</a>",           
    "we_sent_you_an_email": "Te hemos enviado un correo electrónico con instrucciones para restablecer tu contraseña.",           
    "your_email": "Tu correo electrónico",           
    "unable_to_process_your_request": "No se puede procesar tu solicitud. Por favor, asegúrate de que tu dirección de correo electrónico es correcta.",           
    "recover_letter": "{{brandName}} Recuperar carta"
}
};window.locales.it = window.locales.it || {};window.locales.it.authentication = window.locales.it.authentication || {};window.locales.it.authentication.forgot_password = window.locales.it.authentication.forgot_password || {};window.locales.it.authentication.forgot_password = {...window.locales.it.authentication.forgot_password, ...{
    "check_your_email": "Controlla la tua email",           
    "enter_your_email": "Inserisci il tuo indirizzo email e ti invieremo le istruzioni per reimpostare la tua password.",           
    "forgot_your_password": "Password dimenticata?",           
    "reset_my_password": "Reimposta la mia password",           
    "return_to_login": "Torna all'<a ng-click=\"gotoSignIn()\" >Accesso</a>",           
    "we_sent_you_an_email": "Ti abbiamo inviato una email con le istruzioni per reimpostare la tua password.",           
    "your_email": "La tua email",           
    "unable_to_process_your_request": "Impossibile elaborare la tua richiesta. Per favore, assicurati che l'indirizzo email sia corretto.",           
    "recover_letter": "{{brandName}} Recupera lettera"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.authentication = window.locales.zh.authentication || {};window.locales.zh.authentication.forgot_password = window.locales.zh.authentication.forgot_password || {};window.locales.zh.authentication.forgot_password = {...window.locales.zh.authentication.forgot_password, ...{
    "check_your_email": "请检查您的电子邮件",           
    "enter_your_email": "请输入您的电子邮件地址，我们将向您发送重置密码的说明。",           
    "forgot_your_password": "忘记了您的密码？",           
    "reset_my_password": "重置我的密码",           
    "return_to_login": "请返回<a ng-click=\"gotoSignIn()\" >登录</a>",           
    "we_sent_you_an_email": "我们给您发送了一封电子邮件，其中包含重置您的密码的说明。",           
    "your_email": "您的电子邮件",           
    "unable_to_process_your_request": "无法处理您的请求。请确保您的电子邮件地址正确。",           
    "recover_letter": "{{brandName}}恢复信函"           
}
};